let header
let articleHeader
let articleContent

const onWindowScroll = (articleActions) => {
  const stickyCssClass = 'article__actions--sticky'
  const headerHeight = header.offsetHeight
  const topPoint = window.matchMedia('(min-width: 1025px)').matches
    ? articleContent.getBoundingClientRect().top
    : articleHeader.getBoundingClientRect().top
  const scrollCheck = topPoint - headerHeight < 0

  // debugger
  articleActions.classList[scrollCheck ? 'add' : 'remove'](stickyCssClass)
}

const init = (scrollParent = window, container = document) => {
  const articleActions = container.querySelector('.js-article-actions')
  if (articleActions) {
    header = document.querySelector('.site-header')
    articleHeader = document.querySelector('.article__header')
    articleContent = document.querySelector('.article__content')

    scrollParent.addEventListener('scroll', () => {
      onWindowScroll(articleActions)
    })
  }
}

export default init

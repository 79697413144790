export default (observedElement, stickyElement) => {
  const body = document.documentElement
  const isMobile = window.matchMedia('(max-width: 667px)').matches

  if (!observedElement || !stickyElement || !isMobile) {
    return
  }

  const stickyClass = 'show-sticky-buy-button'
  const options = {
    threshold: 0.1
  }

  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        body.classList.remove(stickyClass)
      } else if (entry.boundingClientRect.top < 0) {
        body.classList.add(stickyClass)
      }
    })
  }

  const observer = new IntersectionObserver(handleIntersect, options)
  observer.observe(observedElement)
}

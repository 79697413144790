import gtm from '@/helpers/gtm'

export default {
  trackProductImpressions({ el, model }) {
    const options = {
      root: null,
      rootMargin: '0px 0px 70px 0px',
      threshold: 1.0
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gtm.productImpression(model)
          observer.unobserve(entry.target)
        }
      })
    },
    options)

    observer.observe(el)
  }
}

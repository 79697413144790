<template>
  <div
    v-if="filteredTermsAndFilters.length"
    class="filters__selected"
  >
    <div class="filters__selected__inner">
      <div class="filters__selected__inner__holder">
        <div class="filters__selected__content">
          <active-filters
            :filters="filteredTermsAndFilters"
            :clear-filters-text="clearFiltersText"
            @removeFilter="removeTermsAndFilters"
            @clearFilters="clearTermsAndFilters"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import ActiveFilters from '@/components/active-filters.vue'
import searchQueryState from '@/compositions/search/search-query'
import searchConfig from '@/compositions/search/search-configuration'

export default {
  components: {
    ActiveFilters
  },
  setup() {
    const {
      activeTermsAndFilters,
      removeTermsAndFilters,
      clearTermsAndFilters
    } = searchQueryState.searchQueryUse()
    const { isOnlineFilter, getActiveConfig } = searchConfig.searchConfigurationUse()
    const { filters, translations } = getActiveConfig()
    const filtersWithImages = filters
      .flatMap((a) => a.items)
      .filter((x) => x.filterImage !== null)

    const filteredTermsAndFilters = computed(() => activeTermsAndFilters.value
      .filter((filter) => !(filter.type === 'filter' && isOnlineFilter(filter.name)))
      .map((filter) => {
        // This logic is added to pass filter images which stored in active config
        // categories to active filters
        if (filter.type !== 'filter') {
          return filter
        }

        const filterWithImage = filtersWithImages.find((x) => x.slug === filter.slug)
        if (filterWithImage === undefined) {
          return filter
        }

        filter.filterImage = filterWithImage.filterImage
        filter.filterImageSelected = filterWithImage.filterImageSelected

        return filter
      }))

    return {
      clearFiltersText: translations.clearAllText || '',
      filteredTermsAndFilters,
      removeTermsAndFilters,
      clearTermsAndFilters
    }
  }
}
</script>

const triggerLabel = 'data-gtm-trigger'
const triggerLabelClick = `[${triggerLabel}="click"]`
const triggerElements = document.querySelectorAll(triggerLabelClick)

triggerElements.forEach((item) => {
  item.addEventListener('click', (e) => {
    const data = e.currentTarget.dataset

    if (!data || !data.gtmTracking) {
      return
    }

    window.dataLayer.push(JSON.parse(data.gtmTracking))
  })
})

<template>
  <div
    ref="filterPanel"
    class="filters__panel"
    :class="{ 'is-open': filterPanelIsVisible }"
  >
    <div
      class="filters__panel-overlay"
      @click="hideFilterPanel"
    />
    <div
      class="filters__panel-close"
      role="button"
      tabindex="0"
      @click="hideFilterPanel"
    />
    <div class="filters__panel-inner">
      <div class="filters__panel-title">
        <h3>{{ translations.filtersText }}</h3>
      </div>
      <div class="filters__inner-container">
        <div class="filters__inner-items">
          <div
            class="filters__inner-scroll"
            body-scroll-lock-ignore
          >
            <div class="filters__inner">
              <div
                v-for="(filter, index) in filters"
                :key="index"
                class="filters__column opened"
              >
                <h4 class="filters__column__heading">
                  {{ filter.name }}
                </h4>
                <div class="filters__items-wrap">
                  <ul class="filters__items">
                    <li
                      v-for="(subFilter, index) in filter.items"
                      :key="index"
                      :class="{ 'active': filterIsActive(subFilter) }"
                    >
                      <a
                        :href="getFilterUrl(subFilter.slug)"
                        role="button"
                        tabindex="0"
                        :class="[subFilter.filterImage ? 'filter__icon-button' : 'filter__button']"
                        @click.prevent="onFilterClick(subFilter, filter.items)"
                      >
                        <img
                          v-if="subFilter.filterImage"
                          :alt="subFilter.name"
                          :src="filterIsActive(subFilter) ? subFilter.filterImageSelected : subFilter.filterImage"
                          loading="lazy"
                          width="60"
                          height="10"
                          class="filter__icon-button__icon"
                        >
                        <template v-else>
                          {{ subFilter.name }}
                        </template>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="filters__panel-buttons">
        <button
          tabindex="0"
          class="button-filled"
          @click="hideFilterPanel"
        >
          {{ translations.filtersText }}
        </button>
        <button
          tabindex="0"
          class="button-outline"
          @click="removeAllFilters"
        >
          {{ translations.clearAllText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import useFilters from '@/compositions/useFilters'
import searchConfig from '@/compositions/search/search-configuration'
import searchQueryState from '@/compositions/search/search-query'
import gtm from '@/helpers/gtm'

export default {
  setup() {
    const filterPanel = ref(null)
    const { filterPanelIsVisible, hideFilterPanel, setFilterPanelRef } = useFilters()
    const { getActiveConfig } = searchConfig.searchConfigurationUse()
    const {
      removeAllFilters,
      setSubFilter,
      removeFilter,
      filterIsActive
    } = searchQueryState.searchQueryUse()
    const { filters: allFilters, translations } = getActiveConfig()
    const filters = allFilters.filter((filter) => !filter.hidden)

    const onFilterClick = (filter, filtersToRemove) => {
      if (filterIsActive(filter)) {
        removeFilter(filter)
      } else {
        setSubFilter(filter, filtersToRemove)
      }
      gtm.pushDataLayer({
        event: 'productPageFilterSelection',
        filterName: filter.name,
        filterCategory: filter.name
      })
    }

    const getFilterUrl = (slug) => '/'.concat(slug, '/')

    onMounted(() => {
      setFilterPanelRef(filterPanel.value)
    })

    return {
      filters,
      translations,
      filterPanel,
      filterPanelIsVisible,
      hideFilterPanel,
      filterIsActive,
      removeAllFilters,
      onFilterClick,
      getFilterUrl
    }
  }
}
</script>

<template>
  <div class="active-filters">
    <ul class="filters__selected__container">
      <li
        v-for="(filter, index) in filters"
        :key="index"
        class="active"
        @click="removeFilter(filter)"
        @keyup.enter="removeFilter(filter)"
      >
        <span
          role="button"
          tabindex="0"
          :class="[filter.filterImage ? 'filter__icon-button' : 'filter__button']"
        >
          <img
            v-if="filter.filterImage"
            :alt="filter.name"
            :src="filter.filterImageSelected"
            class="filter__icon-button__icon selected-filter-image"
          >
          <template v-else>
            {{ filter.name }}
          </template>
        </span>
        <span
          role="button"
          aria-label="Remove"
          tabindex="0"
          class="filter__button__remove"
        >
          <span />
        </span>
      </li>
      <li v-if="filters.length > 1">
        <button
          tabindex="0"
          class="filters__selected__clear"
          @click="clearFilters"
          @keyup.enter="clearFilters"
        >
          {{ clearFiltersText }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    filters: {
      type: Array,
      required: true
    },
    clearFiltersText: {
      type: String,
      default: 'Clear Filters'
    }
  },
  methods: {
    removeFilter(filter) {
      this.$emit('removeFilter', filter)
    },
    clearFilters() {
      this.$emit('clearFilters')
    }
  }
}
</script>

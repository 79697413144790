<template>
  <div class="product-card">
    <div class="product-card__inner">
      <a
        :href="model.url"
        class="product-card-link"
        @click="onProductClick"
      >
        <div class="product-card__picture-wrap">
          <div class="product-card__picture-inner-wrap">
            <picture class="product-card__picture">
              <source
                v-for="(source, index) in model.image.srcSets"
                :key="index"
                :srcset="`${source.url} 1x, ${source.url2x} 2x`"
                :media="`(min-width: ${source.minWidth}px)`"
              />
              <img
                :srcset="`${model.image.url} 1x, ${model.image.url2x} 2x`"
                :alt="model.heading"
                :width="model.width"
                :height="model.height"
                loading="lazy"
              >
            </picture>
          </div>
        </div>
        <div class="product-card__information">
          <div>
            <h4 class="product-card__subtitle">{{ model.subHeading }}</h4>
            <h3 class="product-card__title">{{ model.heading }}</h3>
          </div>
        </div>
      </a>

      <buy-button-row
        v-if="model.shopifyInfo !== null"
        :model="model"
        :track-data="trackData"
        class="product-card__buy-row"
      />
    </div>
  </div>
</template>

<script>
import BuyButtonRow from '@/components/products/buy-button-row.vue'
import { getImageSourceUrl } from '@/helpers/imageResizeUrl'
import EcommerceTracking from '@/helpers/ecommerce-tracking'
import gtm from '@/helpers/gtm'

export default {
  components: {
    BuyButtonRow
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    listName: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  computed: {
    trackData() {
      const id = this.model.shopifyInfo?.shopifyProductId || 0
      const price = this.model.shopifyInfo?.variants[0].price || 0
      const variant = this.model.shopifyInfo?.variants[0].optionValue || ''
      const {
        heading, subHeading, brand
      } = this.model

      return {
        position: this.position,
        name: heading,
        id,
        price,
        category: subHeading,
        brand,
        variant,
        list: this.listName,
        quantity: 1
      }
    }
  },
  mounted() {
    EcommerceTracking.trackProductImpressions({
      el: this.$el,
      model: this.trackData
    })
  },
  methods: {
    onProductClick() {
      if (this.model.shopifyInfo !== null) {
        gtm.productClicks(this.trackData)
      } else {
        gtm.pushDataLayer({
          event: 'viewContent',
          content_type: 'product',
          content_ids: 0,
          currency: 'SEK',
          value: 0
        })
      }
    }
  }
}
</script>

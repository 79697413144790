<template>
  <div
    ref="filterElement"
    :class="cssClass"
    :style="fixed ? contentStyles : ''"
  >
    <div class="filters__inner-wrap">
      <div class="filters__bar">
        <div class="filters__bar-column">
          <div
            class="filters__opener"
            role="button"
            @click="showFilterPanel"
          >
            <IconFilter class="filters__icon" />
            <span>
              {{ translations.filtersText }}
            </span>
          </div>

          <search-form />
          <buyable-filter />
        </div>
      </div>

      <mixed-filters />
    </div>
  </div>
</template>

<script>
import {
  ref, computed, onMounted, onUnmounted
} from 'vue'

import debounce from '@/helpers/debounce'
import MixedFilters from '@/components/mixed-active-filters.vue'
import SearchForm from '@/components/products/search-form.vue'
import BuyableFilter from '@/components/products/buyable-filter.vue'
import searchQueryState from '@/compositions/search/search-query'
import searchConfig from '@/compositions/search/search-configuration'
import useFilters from '@/compositions/useFilters'
import useSticky from '@/compositions/useSticky'
import IconFilter from '@/assets/svg/filter.svg'

export default {
  components: {
    BuyableFilter,
    SearchForm,
    MixedFilters,
    IconFilter
  },
  setup() {
    const filterElement = ref(null)
    const fixed = ref(false)
    const pinned = ref(false)
    const lastPosition = ref(0)
    const { showFilterPanel } = useFilters()
    const { activeTermsAndFilters } = searchQueryState.searchQueryUse()
    const { isOnlineFilter, getActiveConfig } = searchConfig.searchConfigurationUse()
    const { translations } = getActiveConfig()
    const filteredTermsAndFilters = computed(() => activeTermsAndFilters.value.filter((filter) => !(filter.type === 'filter' && isOnlineFilter(filter.name))))
    const cssClass = computed(() => [
      'filters',
      {
        'is-pinned': pinned.value,
        'is-fixed': fixed.value,
        'has-active-filters': filteredTermsAndFilters.value.length > 0
      }
    ])
    const contentStyles = {
      height: '0'
    }

    const updateScrollValues = debounce(() => {
      const newValues = useSticky(filterElement.value, lastPosition.value)
      fixed.value = newValues.fixed
      pinned.value = newValues.pinned
      lastPosition.value = newValues.newPosition
      contentStyles.height = `${newValues.elementHeight}px`
    }, 10)

    onMounted(() => {
      updateScrollValues()
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', updateScrollValues)
    })

    window.addEventListener('scroll', updateScrollValues)

    return {
      fixed,
      cssClass,
      contentStyles,
      filterElement,
      filteredTermsAndFilters,
      translations,
      showFilterPanel
    }
  }
}
</script>

<template>
  <div
    class="c-text-image"
    :class="[model.theme.themeCssClass]"
  >
    <div
      class="c-text-image-wrap"
      :class="[
        { 'u-flex-direction-row-reverse': model.theme.isReverse },
        { 'has-image': model.image && model.image.imageUrl }
      ]"
    >
      <div class="c-text-image__content">
        <div class="c-text-image__content-wrap">
          <span
            v-if="model.subTitle"
            class="kicker"
            v-text="model.subTitle"
          />
          <h1 class="site-block-heading">
            {{ model.title }}
          </h1>
          <p
            v-if="model.text"
            v-text="model.text"
          />
          <div
            v-if="model.link && model.link.url"
            class="u-flex"
          >
            <a
              class="button-themed"
              :href="model.link.url"
              :title="model.link.text"
              v-text="model.link.text"
            />
          </div>
        </div>
      </div>
      <div
        v-if="model.image && model.image.imageUrl"
        class="c-text-image__visual"
      >
        <picture>
          <img
            :src="model.image.imageUrl"
            width="900"
            height="500"
            :alt="model.title"
          >
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div class="item-card">
    <div class="item-card__inner">
      <a
        :href="model.url"
        class="item-card-link"
        @click="onItemClick"
      >
        <picture class="item-card__picture">
          <source
            v-for="(source, index) in model.image.srcSets"
            :key="index"
            :srcset="`${source.url} 1x, ${source.url2x} 2x`"
            :media="`(min-width: ${source.minWidth}px)`"
          />
          <img
            :srcset="`${model.image.url} 1x, ${model.image.url2x} 2x`"
            :alt="model.heading"
            :width="model.width"
            :height="model.height"
            loading="lazy"
          >
        </picture>
        <div class="item-card__info">
          <div class="item-card__info__inner">
            <h4 class="item-card__subtitle">{{ model.subHeading }}</h4>
            <h3 class="item-card__title">{{ model.heading }}</h3>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { getImageSourceUrl } from '@/helpers/imageResizeUrl'
import EcommerceTracking from '@/helpers/ecommerce-tracking'
import gtm from '@/helpers/gtm'

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    listName: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  computed: {
    trackData() {
      const {
        name
      } = this.model

      return {
        position: this.position,
        name,
        list: this.listName
      }
    }
  },
  mounted() {
    EcommerceTracking.trackProductImpressions({
      el: this.$el,
      model: this.trackData
    })
  },
  methods: {
    getImageUrl(width, height) {
      return getImageSourceUrl(this.model.image, width, height, 'max')
    },
    onItemClick() {
      gtm.productClicks(this.trackData)
    }
  }
}
</script>

import EcommerceTracking from '@/helpers/ecommerce-tracking'
import gtm from '@/helpers/gtm'

export default (container = document) => {
  const productCards = container.querySelectorAll('.js-product-card')
  if (productCards.length === 0) {
    return
  }
  productCards.forEach((card) => {
    const {
      productName: name,
      defaultPrice: price,
      productId: id,
      category,
      brand,
      variant,
      listName: list,
      position
    } = card.dataset

    const trackData = {
      name,
      id,
      price,
      category,
      brand,
      variant,
      list,
      position
    }

    EcommerceTracking.trackProductImpressions({
      el: card,
      model: trackData
    })

    const cardLink = card.querySelector('.js-product-card-link')

    cardLink.addEventListener('click', () => {
      gtm.productClicks(trackData)
    }, false)
  })
}

const getImageSourceUrl = (
  url,
  width = 0,
  height = 0,
  mode = 'crop',
  transform = 'downfill',
  quality = 80,
  bgcolor = 'fff'
) => {
  const params = [
    `mode=${mode}`,
    `quality=${quality}`,
    `transform=${transform}`,
    'format=jpeg',
    `bgcolor=${bgcolor}`
  ]
  if (width > 0) {
    params.push(`width=${width}`)
  }
  if (height > 0) {
    params.push(`height=${height}`)
  }
  return `${url}?${params.join('&')}`
}
// eslint-disable-next-line import/prefer-default-export
export { getImageSourceUrl }

import { computed, reactive } from 'vue'

const searchQueryState = reactive({
  take: 24,
  page: 1,
  terms: [],
  searchType: null,
  sorting: null,
  view: null,
  activeFilters: [],
  language: null,
  hero: null,
  updated: Date.now(),
  initialized: false
})

export default {
  searchQueryUse(routeData) {
    const state = searchQueryState

    const resetPage = () => {
      state.page = 1
    }

    const incrementPage = () => {
      state.page += 1
    }

    const setHero = (value) => {
      state.hero = value
    }

    const activeTermsAndFilters = computed(() => {
      const filters = state.activeFilters.map((filter) => {
        const type = 'filter'
        return {
          name: filter.name,
          type
        }
      })
      const terms = state.terms.map((name) => {
        const type = 'term'
        return {
          name,
          type
        }
      })
      return [...filters, ...terms]
    })

    const resetLastUpdated = () => {
      state.updated = Date.now()
    }

    if (!state.initialized && routeData) {
      state.activeFilters = routeData.activeFilters || []
      state.searchType = routeData.searchType
      state.terms = routeData.terms || []
      state.sorting = routeData.sorting
      state.view = routeData.view
      state.language = routeData.language
      state.page = routeData.page
      state.initialized = true
      resetLastUpdated()
    }

    const setSearchType = (searchType) => {
      if (state.searchType === searchType) {
        return
      }

      state.searchType = searchType
      state.page = 1
      state.sorting = null
      state.view = null
      state.activeFilters = []
      state.hero = null

      resetLastUpdated()
    }

    const setSorting = (sorting) => {
      if (state.sorting === sorting) {
        return
      }

      state.sorting = sorting
      resetPage()
      resetLastUpdated()
    }

    const setView = (view) => {
      if (state.view === view) {
        return
      }

      resetPage()
      state.view = view
    }

    const setActiveFilters = (activeFilters) => {
      state.activeFilters = activeFilters || []
      resetPage()
      resetLastUpdated()
    }

    const addFilter = (filter) => {
      const index = state.activeFilters.map((activeFilter) => activeFilter.slug)
        .indexOf(filter.slug)
      if (index === -1) {
        const tempArray = state.activeFilters
        tempArray.push(filter)
        setActiveFilters(tempArray)
      }
    }

    const removeFilter = (filter) => {
      const index = state.activeFilters.map((activeFilter) => activeFilter.slug)
        .indexOf(filter.slug)
      if (index > -1) {
        const tempArray = state.activeFilters
        tempArray.splice(index, 1)
        setActiveFilters(tempArray)
        setHero(null)
      }
    }

    const removeFilterByName = (filterName) => {
      const index = state.activeFilters.map((activeFilter) => activeFilter.name)
        .indexOf(filterName)
      if (index > -1) {
        const tempArray = state.activeFilters
        tempArray.splice(index, 1)
        setActiveFilters(tempArray)
      }
    }

    const setSubFilter = (filter, filtersToRemove) => {
      filtersToRemove.forEach((x) => {
        removeFilter(x)
      })
      addFilter(filter)
    }

    const removeAllFilters = () => {
      setActiveFilters(null)
    }

    const setTerms = (terms) => {
      state.terms = terms || []
      resetPage()
      resetLastUpdated()
    }

    const addTerm = (term) => {
      const index = state.terms.indexOf(term)
      if (index === -1) {
        const tempArray = state.terms
        tempArray.push(term)
        setTerms(tempArray)
      }
    }

    const removeTerm = (term) => {
      const index = state.terms.indexOf(term)
      if (index > -1) {
        const tempArray = state.terms
        tempArray.splice(index, 1)
        setTerms(tempArray)
      }
    }

    const clearTermsAndFilters = () => {
      removeAllFilters(null)
      setTerms()
    }

    const removeTermsAndFilters = (term) => {
      if (term.type === 'filter') {
        removeFilterByName(term.name)
      }

      if (term.type === 'term') {
        removeTerm(term.name)
      }
    }

    const filterIsActive = (filter) => state.activeFilters
      && state.activeFilters.length > 0
      && state.activeFilters.map((activeFilter) => activeFilter.slug)
        .indexOf(filter.slug) > -1

    return {
      searchQuery: state,
      activeFilters: computed(() => state.activeFilters),
      activeTerms: computed(() => state.terms),
      activeTermsAndFilters,
      language: computed(() => state.language),
      searchType: computed(() => state.searchType),
      hero: computed(() => state.hero),
      addFilter,
      removeFilter,
      setSubFilter,
      removeAllFilters,
      setTerms,
      addTerm,
      removeTerm,
      clearTermsAndFilters,
      removeTermsAndFilters,
      setActiveFilters,
      setSearchType,
      setSorting,
      setView,
      incrementPage,
      setHero,
      filterIsActive
    }
  }
}

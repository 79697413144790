<template>
  <div
    v-if="cheeseFilter && cheeseFilter.items.length"
    class="visual-filters"
  >
    <ul class="visual-filters__list">
      <li
        v-for="filter in cheeseFilter.items"
        :key="filter.slug"
        class="visual-filters__list-item"
      >
        <span
          :class="{ 'active': filterIsActive(filter) }"
          role="button"
          tabindex="0"
          class="visual-filter"
          @click="onFilterClick(filter, cheeseFilter.items)"
          @keyup.enter="onFilterClick(filter, cheeseFilter.items)"
        >
          <picture class="visual-filter-image">
            <img
              v-if="filter.displayImage"
              :src="getImageSourceUrl(filter.displayImage, 90, 90, 'max', 'downfill', 80, '9A8673')"
              :alt="filter.name"
              loading="lazy"
              width="90"
              height="90"
            >
          </picture>
          {{ filter.name }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import searchConfig from '@/compositions/search/search-configuration'
import searchQueryState from '@/compositions/search/search-query'
import { getImageSourceUrl } from '@/helpers/imageResizeUrl'
import gtm from '@/helpers/gtm'

export default {
  setup() {
    const { getActiveConfig } = searchConfig.searchConfigurationUse()
    const {
      setSubFilter,
      removeFilter,
      setHero,
      filterIsActive
    } = searchQueryState.searchQueryUse()
    const { filters } = getActiveConfig()

    const cheeseFilter = filters.find((filter) => filter.isVisual)
    if(cheeseFilter) {
      cheeseFilter.items.some((filter) => {
        if (filterIsActive(filter) && filter.hero) {
          setHero(filter.hero)
          return true
        }
        return false
      })
    }

    const onFilterClick = (filter, filtersToRemove) => {
      if (filterIsActive(filter)) {
        removeFilter(filter)
      } else {
        setSubFilter(filter, filtersToRemove)
        setHero(filter.hero ? filter.hero : null)
      }
      gtm.pushDataLayer({
        event: 'visualFilterClick',
        visualFilter: filter.name
      })
    }

    return {
      cheeseFilter,

      filterIsActive,
      onFilterClick,
      getImageSourceUrl
    }
  }
}
</script>

<template>
  <div
    :class="{ 'is-loading': loading }"
    class="newsletter-block"
  >
    <div class="newsletter-image">
      <img
        :src="model.imageUrl"
        loading="lazy"
        width="360"
        height="490"
        alt="Newsletter signup"
      >
    </div>
    <div class="newsletter-content">
      <template v-if="isSuccess">
        <IconLogo class="newsletter-content__logo" />
        <span class="newsletter-title">
          {{ model.successTitle }}
        </span>
        <div
          class="newsletter-text"
          v-html="model.successText"
        />
      </template>
      <template v-else-if="isError">
        <IconLogo class="newsletter-content__logo" />
        <span class="newsletter-title">
          <span class="u-color--pink">*</span>
          {{ model.errorTitle }}
        </span>
        <div
          class="newsletter-text"
          v-html="model.errorText"
        />
      </template>
      <template v-else>
        <IconLogo class="newsletter-content__logo" />
        <div class="newsletter-text">
          <p>
            {{ model.text }}
          </p>
        </div>
        <form
          @submit.prevent="verifyRecaptcha"
        >
          <div class="form-row">
            <input
              v-model="formModel.version"
              type="hidden"
            >
            <input
              v-model="formModel.firstName"
              type="text"
              required
              class="form-field"
              :placeholder="model.firstNameLabel"
            >
          </div>
          <div class="form-row">
            <input
              v-model="formModel.lastName"
              type="text"
              required
              class="form-field"
              :placeholder="model.lastNameLabel"
            >
          </div>
          <div class="form-row">
            <input
              v-model="formModel.email"
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              class="form-field"
              :placeholder="model.emailLabel"
            >
          </div>
          <div class="form-row form-row--checkbox">
            <label class="form-row--choice__item">
              <input
                v-model="formModel.signup"
                type="checkbox"
                class="form-checkbox"
                required
              >
              <div
                class="form-choice__label form-choice__label--small"
                v-html="model.subscriptionFormHtml"
              />
              <span class="form-choice__indicator" />
            </label>
          </div>
          <div class="form-row form-row--checkbox">
            <label class="form-row--choice__item">
              <input
                v-model="formModel.concent"
                type="checkbox"
                class="form-checkbox"
                required
              >
              <div
                class="form-choice__label form-choice__label--small"
                v-html="model.privacyPolicyText"
              />
              <span class="form-choice__indicator" />
            </label>
          </div>
          <div class="form-row u-flex u-flex-justify-center">
            <input
              type="submit"
              :value="model.submitButtonText"
              class="newsletter-button"
            >
          </div>
        </form>
        <recaptcha
          :id="model.id"
          ref="recaptcha"
          :site-key="model.recaptchaSiteKey"
          @submit="onSubmit"
        />
      </template>
    </div>
    <!-- Loader -->
    <div
      v-if="loading"
      class="loader loader-visible"
    >
      <span class="cssload-loader" />
    </div>
  </div>
</template>

<script>
import IconLogo from '@/assets/svg/fallbygdens.svg'
import Recaptcha from '@/components/recaptcha.vue'
import api from '@/api'
import {
  reactive, ref
} from 'vue'
import gtm from '@/helpers/gtm'

export default {
  components: {
    IconLogo,
    Recaptcha
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    trackingListName: {
      type: String,
      default: 'Newsletter block'
    }
  },
  setup(props) {
    const loading = ref(false)
    const isSuccess = ref(false)
    const isError = ref(false)
    const recaptcha = ref(null)

    const formModel = reactive({
      firstName: '',
      lastName: '',
      email: '',
      concent: false,
      signup: false,
      version: props.model.permissionBlockVersion
    })

    const onSubmit = ({ recaptchaToken }) => {
      isSuccess.value = false
      isError.value = false
      loading.value = true

      api.subscribeUser(formModel, recaptchaToken)
        .then(() => {
          isSuccess.value = true
          gtm.pushDataLayer({
            event: 'newsletterSuccessfulSubmit',
            status: 'success',
            list: props.trackingListName
          })
        }).catch((error) => {
          console.error(error)
          isError.value = true
          gtm.pushDataLayer({
            event: 'newsletterSuccessfulSubmit',
            status: 'error',
            list: props.trackingListName
          })
        }).then(() => {
          loading.value = false
        })
    }

    const verifyRecaptcha = (params) => {
      recaptcha.value.verifyRecaptcha(params)
    }

    return {
      isSuccess,
      isError,
      formModel,
      recaptcha,
      loading,

      onSubmit,
      verifyRecaptcha
    }
  }
}
</script>

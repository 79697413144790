<template>
  <div
    :id="recaptchaComponentId"
    data-size="invisible"
  />
</template>
<script>
import { onMounted, reactive, ref } from 'vue'
import useRecaptcha from '@/compositions/useRecaptcha'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    siteKey: {
      type: String,
      required: true
    }
  },
  setup(props, context) {
    const recaptchaComponentId = ref(`recaptcha_${props.id}`)
    let params = reactive({})
    const {
      addComponent,
      setSiteKey,
      verify,
      renderCaptcha
    } = useRecaptcha()

    const recaptchaSubmitCallback = (recaptchaToken) => {
      if (!recaptchaToken || !recaptchaToken.length) {
        return
      }
      context.emit('submit', { recaptchaToken, params: params.value })
    }

    const verifyRecaptcha = (parameters) => {
      params = parameters
      verify(recaptchaComponentId.value)
    }

    addComponent({
      recaptchaElementId: recaptchaComponentId.value,
      recaptchaSubmitCallback
    })

    onMounted(() => {
      setSiteKey(props.siteKey)
      renderCaptcha()
    })

    return {
      recaptchaComponentId,
      verifyRecaptcha
    }
  }
}
</script>

const showMoreText = window.showMoreText || 'Show more'
const showLessText = window.showLessText || 'Show less'
const activeClass = 'is-expanded'
const ellipsisClass = 'has-ellipsis'

const truncate = (item) => {
  if (item.classList.contains('is-truncated')) {
    return
  }
  let showMoreButton
  let itemHeight
  const { colorCode } = item.dataset

  const insertButton = () => {
    const button = document.createElement('button')
    button.className = 'show-more-button'
    button.innerHTML = `
      <span class="show">${showMoreText}</span>
      <span class="hide">${showLessText}</span>
    `
    item.after(button)
    showMoreButton = item.nextSibling
  }

  const collapse = () => {
    item.classList.remove(activeClass)
    item.style.height = `${itemHeight}px`
  }

  const expand = () => {
    item.classList.add(activeClass)
    item.style.height = `${item.scrollHeight}px`
  }

  const init = () => {
    item.classList.add('is-truncated')
    item.classList.remove(activeClass)
    item.removeAttribute('style')
    itemHeight = item.offsetHeight

    if (item.scrollHeight > itemHeight) {
      item.classList.add(ellipsisClass)
      item.style.height = `${itemHeight}px`
    } else {
      item.classList.remove(ellipsisClass)
    }
  }

  const toggleFullText = () => {
    if (item.classList.contains(activeClass)) {
      collapse()
    } else {
      expand()
    }
  }

  const attachEvents = () => {
    window.addEventListener('resize', init)

    showMoreButton.addEventListener('click', toggleFullText)
  }

  init()
  insertButton()
  attachEvents()
  if (colorCode) {
    showMoreButton.style.color = colorCode
  }
}

export default function (container = document) {
  const truncatedElements = container.querySelectorAll('.js-ellipsis')

  if (!truncatedElements.length) {
    return
  }

  Array.from(truncatedElements).forEach(truncate)
}

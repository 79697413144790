import gtm from '@/helpers/gtm'

export default () => {
  const ribbon = document.querySelector('.js-usp-ribbon')
  ribbon.addEventListener('click', (e) => {
    const { target } = e
    if (target.tagName === 'A') {
      gtm.pushDataLayer({
        event: 'ribbonClick',
        title: target.innerText.trim()
      })
    }
  })
}

import { reactive } from 'vue'

const state = reactive({
  sitekey: '',
  recaptchaComponents: []
})

const renderCaptcha = () => {
  if (window.grecaptcha) {
    state.recaptchaComponents.forEach((component) => {
      if (!component.rendered) {
        window.grecaptcha.render(component.recaptchaElementId, {
          sitekey: state.sitekey,
          badge: 'bottomleft',
          size: 'invisible',
          callback: component.recaptchaSubmitCallback
        })
        component.rendered = true
      }
    })
  }
}

const verify = (recaptchaElementId) => {
  if (window.grecaptcha) {
    const componentIndex = state.recaptchaComponents
      .findIndex((x) => x.recaptchaElementId === recaptchaElementId)
    const { recaptchaSubmitCallback } = state.recaptchaComponents[componentIndex]
    const response = window.grecaptcha.getResponse(componentIndex)

    if (response.length === 0) {
      window.grecaptcha.execute(componentIndex)
    } else {
      recaptchaSubmitCallback()
    }
  }
}

const reset = (recaptchaElementId) => {
  const componentIndex = state.recaptchaComponents
    .findIndex((x) => x.recaptchaElementId === recaptchaElementId)
  if (window.grecaptcha) {
    window.grecaptcha.reset(componentIndex)
  }
}

const initialize = () => {
  const recaptchaScript = document.createElement('script')
  recaptchaScript.setAttribute('src', 'https://www.recaptcha.net/recaptcha/api.js?onload=renderCaptcha&render=explicit')
  document.head.appendChild(recaptchaScript)
}

const setSiteKey = (value) => {
  if (state.sitekey) {
    return
  }
  state.sitekey = value
}

window.renderCaptcha = function () {
  renderCaptcha()
}

initialize()

export default () => {
  const addComponent = ({
    recaptchaElementId,
    recaptchaSubmitCallback
  }) => {
    state.recaptchaComponents.push({
      recaptchaElementId,
      recaptchaSubmitCallback,
      rendered: false
    })
  }

  return {
    renderCaptcha,
    setSiteKey,
    addComponent,
    reset,
    verify
  }
}

import 'core-js/stable'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import VueRouter from 'vue-router'
import VModal from 'vue-js-modal'
// eslint-disable-next-line import/no-extraneous-dependencies
import vueUtil from '@/vue-util'

import '@/stylus/critical.styl'
import '@/stylus/main.styl'

import responsive from '@/helpers/breakpoint'
import navigation from '@/components/navigation'
import filters from '@/components/filters'
import endlessScroll from '@/components/endless-scroll'
import copyLink from '@/components/copy-link'
import '@/helpers/detectors'
import '@/helpers/gtmAttributeTracking'

// eslint-disable-next-line no-unused-vars

import '@/components/search-field'
import TextTruncate from '@/components/text-truncate'
import uspRibbon from '@/components/usp-ribbon'
import supportPopup from '@/components/support-popup'
import expandCollapse from '@/compositions/expand-collapse'
import initSlider from '@/compositions/slider'
import simpleExpand from '@/compositions/expand'

import SearchOverview from '@/components/search/search-overview.vue'
import ProductsOverview from '@/components/products/product-overview.vue'
import NewsletterBlock from '@/components/newsletter-block.vue'
import BuyProduct from '@/components/products/buy-product.vue'
import BuyProductCard from '@/components/products/buy-product-card.vue'
import NewsletterModal from '@/components/newsletter-modal.vue'
import Slider from '@/components/slider.vue'
import VideoBlock from '@/components/video-block'
import VideoPlaceholder from '@/components/VideoPlaceholder.vue'
import ShopifyBasket from '@/components/shopifyBasket.vue'
import BasketButton from '@/components/basket-button.vue'
import ImageTextBlock from '@/components/image-text-block.vue'
import stickyActions from '@/components/sticky-actions'

Vue.config.productionTip = false

NodeList.prototype.forEach = Array.prototype.forEach
Vue.use(VueRouter)
Vue.use(VModal)

const vueComponents = {
  VideoPlaceholder,
  ProductsOverview,
  NewsletterModal,
  NewsletterBlock,
  BuyProduct,
  BuyProductCard,
  Slider,
  ShopifyBasket,
  BasketButton,
  SearchOverview,
  ImageTextBlock
}

// And all Vue components on the page
vueUtil.mountComponents(vueComponents, null)

responsive()
navigation.init()
endlessScroll.init()
filters.init()
copyLink()
uspRibbon()
supportPopup()
initSlider()
stickyActions()
window.addEventListener('load', () => {
  TextTruncate()
})

const expandables = document.querySelectorAll('.js-expandable')
expandables.forEach((elm) => {
  expandCollapse(elm)
})

const videoBlocks = document.querySelectorAll('.js-video-block')
videoBlocks.forEach((element) => {
  // eslint-disable-next-line no-unused-vars
  const youtube = new VideoBlock(element)
})

const expandButtons = document.querySelectorAll('.js-expand')
expandButtons.forEach((elm) => {
  simpleExpand(elm)
})

import { tns } from 'tiny-slider/src/tiny-slider.module'

const getDirection = () => (document.querySelector('[dir]') ? document.querySelector('[dir]').getAttribute('dir') || 'ltr' : 'ltr')

const config = {
  third: {
    responsive: {
      500: {
        edgePadding: 0,
        items: 2.2,
        gutter: 20
      },
      768: {
        edgePadding: 0,
        items: 3,
        gutter: 30
      }
    }
  },
  quarter: {
    responsive: {
      375: {
        edgePadding: 20,
        items: 2.2,
        gutter: 10
      },
      768: {
        edgePadding: 30,
        items: 3.3,
        gutter: 20
      },
      1024: {
        edgePadding: 0,
        items: 4,
        gutter: 30
      }
    }
  },
  uspRibbon: {
    items: 1,
    controls: false,
    nav: false,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    edgePadding: 0,
    gutter: 30,
    responsive: {
      600: {
        items: 2,
        nav: false,
        controls: false,
        autoplay: true,
        autoplayTimeout: 3000,
      },
      1023: {
        items: 4,
        nav: false,
        controls: false,
        autoplay: false,
        mouseDrag: false
      }
    }
  }

}

export default (
  sliderType,
  sliderElement,
  controlsElement,
  sliderProps = {}
) => {
  const typedConfig = config[sliderType] || config.quarter

  return tns({
    container: sliderElement,
    controlsContainer: controlsElement,
    autoWidth: false,
    items: 1.1,
    slideBy: 1,
    edgePadding: 20,
    gutter: 10,
    loop: false,
    rewind: (sliderElement.offsetWidth < 768) || false,
    nav: false,
    mouseDrag: true,
    swipeAngle: false,
    textDirection: getDirection(),
    ...typedConfig,
    ...sliderProps
  })
}

import { reactive, computed } from 'vue'
import searchQueryState from './search-query'

const searchConfigurationState = reactive({
  searchConfiguration: {},
  initialized: false
})

export default {
  searchConfigurationUse(configuration) {
    const { setSearchType } = searchQueryState.searchQueryUse()
    const state = searchConfigurationState

    if (configuration && !state.initialized) {
      state.searchConfiguration = configuration
      setSearchType(configuration.type)
      state.initialized = true
    }
    function getActiveConfig() {
      return state.searchConfiguration
    }
    function isOnlineFilter(filterName) {
      const { onlineFilterName } = getActiveConfig()
      return filterName === onlineFilterName
    }

    return {
      getActiveConfig,
      isOnlineFilter,

      searchConfiguration: computed(() => state.searchConfiguration)
    }
  }
}

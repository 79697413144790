<template>
  <div class="buy-row">
    <buy-button
      :variant-id="variantId"
      :track-data="trackData"
    />
    <span
      v-if="shopifyInfo.weight"
      class="product-card-weight"
      v-text="shopifyInfo.weight"
    />
    <strong class="product__price">
      <span>{{ currentVariant.price }}</span>
      <span class="currency">{{ currentVariant.priceUnit }}</span>
    </strong>
  </div>
</template>

<script>
import { computed } from 'vue'
import BuyButton from '@/components/products/buy-button.vue'

export default {
  components: {
    BuyButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    trackData: {
      type: Object,
      required: true
    },
    quantity: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const shopifyInfo = computed(() => props.model.shopifyInfo)
    const currentVariant = computed(() => shopifyInfo.value.variants[0])
    const variantId = computed(() => currentVariant.value.shopifyBase64EncodedId)

    return {
      shopifyInfo,
      currentVariant,
      variantId
    }
  }
}
</script>

const Navigation = () => {
  const module = {}
  let $body
  let $navigation
  let $burgerButton
  let $footer
  let $navigationItems
  let $dropDowns
  let $isTouchDevice
  let dropdownTimer = null
  let navigationIsClosed = true

  const trackNavigation = (menuName) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        menuName,
        event: 'menuSelection'
      })
    }
  }

  const toggleNavigation = () => {
    if (navigationIsClosed) {
      $body.classList.remove('navigation-opened')
      $burgerButton.classList.remove('navigation-opened')
      $footer.classList.remove('footer-visible')
      $navigation.classList.add('navigation-closing')

      setTimeout(() => {
        $navigation.classList.remove('navigation-opened', 'navigation-closing')
        $navigation.removeAttribute('style')
      }, 450)
    } else {
      if (window.breakpoint.value === 'small') {
        $navigation.style.height = `${window.innerHeight}px`
      }
      $body.classList.add('navigation-opened')
      $navigation.classList.remove('navigation-closing')
      $burgerButton.classList.add('navigation-opened')
      $navigation.classList.add('navigation-opened')
      $footer.classList.add('footer-visible')
    }
  }

  const cancelTimer = () => {
    if (dropdownTimer) {
      clearTimeout(dropdownTimer)
    }
  }

  const updateSubNavigation = (urlFilterIds) => {
    const $subItems = []
    Array.from($navigationItems).forEach((item) => {
      if (item.classList.contains('active')) {
        item.classList.remove('active')
        const items = item.querySelectorAll('li')
        if (items) {
          $subItems.push(...items)
        }
      }
    })

    if (urlFilterIds.length) {
      const selected = urlFilterIds.sort().join(',')
      $subItems.forEach((item) => {
        const { urlSegments } = item.dataset
        const segments = urlSegments.split(',').sort().join(',')

        if (segments === selected) {
          item.classList.add('active')
        }
      })
    }
  }

  const attachEvents = () => {
    $burgerButton.addEventListener('click', (e) => {
      e.preventDefault()
      navigationIsClosed = !navigationIsClosed
      toggleNavigation()
    })

    $navigationItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        const $item = e.currentTarget
        $item.classList.toggle('hover')
        if (!$item.classList.contains('active')) {
          trackNavigation($item.childNodes[0].innerText)
        }
      })
    })

    $dropDowns.forEach((item) => {
      item.addEventListener('mouseover', () => {
        cancelTimer()
      })
    })

    if (!$isTouchDevice) {
      $navigationItems.forEach((item) => {
        item.addEventListener('mouseover', (e) => {
          const $others = [...$navigationItems].filter((element) => element !== e.currentTarget)
          $others.forEach((subItem) => {
            if (subItem.classList.contains('hover')) {
              e.currentTarget.classList.add('noanim')
            }
            subItem.classList.remove('hover', 'noanim')
          })
          cancelTimer()
          e.currentTarget.classList.add('hover')
        })
      })

      $navigationItems.forEach((item) => {
        item.addEventListener('mouseout', (e) => {
          const target = e.currentTarget
          dropdownTimer = setTimeout(() => {
            target.classList.remove('hover', 'noanim')
            dropdownTimer = null
          }, 500)
        })
      })
    }

    window.addEventListener('resize', () => {
      if (!navigationIsClosed && window.breakpoint.value === 'small') {
        $navigation.style.height = `${window.innerHeight}px`
      } else if (!navigationIsClosed) {
        $navigation.removeAttribute('style')
      }
    })

    document.body.addEventListener('navigation.filters', (e) => {
      updateSubNavigation(e.detail.urlFilterIds)
    })

    document.body.addEventListener('restore.filters', (e) => {
      updateSubNavigation(e.detail.filterUrls)
    })
  }

  const expandFirstItem = () => {
    $navigationItems[0].classList.add('hover')
  }

  module.init = () => {
    $navigation = document.querySelector('.js-navigation-container')
    if (!$navigation) {
      return
    }
    $body = document.body
    $burgerButton = document.querySelector('.js-navigation-button')
    $navigationItems = document.querySelectorAll('.js-navigation-container > ul > li')
    $dropDowns = $navigation.querySelectorAll('.js-list-dropdown')
    $footer = document.querySelector('.js-footer')
    $isTouchDevice = document.querySelector('.touchevents')

    attachEvents()

    if ($isTouchDevice) {
      expandFirstItem()
    }
  }

  return module
}

export default new Navigation()

export default (
  element
) => {
  const opener = element.querySelector('.js-expandable-opener')
  const content = element.querySelector('.js-expandable-content')
  const cssClass = 'expanded'
  const defaults = {
    isExpandedByDefault: element.dataset.expanded || false,
    minHeight: element.dataset.minHeight || 0
  }

  const options = {
    isExpanded: defaults.isExpandedByDefault,
    isAnimating: false
  }

  const expand = () => {
    options.isAnimating = true
    element.classList.add(cssClass)

    const listener = () => {
      options.isAnimating = false
      options.isExpanded = true
      content.style.cssText = ''

      content.removeEventListener('transitionend', listener, false)
    }

    content.addEventListener('transitionend', listener, false)

    setTimeout(() => {
      content.style.cssText = `
        height: ${content.scrollHeight}px
      `
    }, 50)

    if (options.trackExpand) {
      options.trackExpand()
    }
  }

  const collapse = () => {
    options.isAnimating = true

    const listener = () => {
      options.isAnimating = false
      element.classList.remove(cssClass)

      content.removeEventListener('transitionend', listener, false)
    }

    content.addEventListener('transitionend', listener, false)

    content.style.cssText = `
        overflow: hidden;
        height: ${content.scrollHeight}px
    `

    setTimeout(() => {
      options.isExpanded = false
      content.style.cssText = `
        overflow: hidden;
        height: ${defaults.minHeight}px
      `
    }, 50)

    if (options.trackCollapse) {
      options.trackCollapse()
    }
  }

  const toggle = () => {
    if (options.isAnimating) {
      return
    }
    if (options.isExpanded) {
      collapse()
    } else {
      expand()
    }
  }

  opener.addEventListener('click', toggle, false)

  if (defaults.isExpandedByDefault) {
    expand()
  } else {
    content.style.cssText = `
      overflow: hidden;
      height: ${defaults.minHeight}px
    `
  }
}

<template>
  <div
    :class="{ 'is-open': isOpen }"
    class="search-field"
  >
    <form
      class="search-form"
      @submit.prevent="onSubmit"
      @focusin="isOpen = true"
      @focusout="isOpen = false"
    >
      <input
        v-model.trim="searchValue"
        class="search-field__input"
        :placeholder="searchPlaceholderText"
        type="text"
      >
      <span class="search-field__icon">
        <IconSearch class="search__icon" />
      </span>
      <span class="search-field__close">
        <svg class="close__icon">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close-circle" />
        </svg>
      </span>
      <input class="search-field__submit" type="submit">
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import searchQueryState from '@/compositions/search/search-query'
import searchConfig from '@/compositions/search/search-configuration'
import gtm from '@/helpers/gtm'
import IconSearch from '@/assets/svg/search.svg'

export default {
  components: {
    IconSearch
  },
  setup() {
    const isOpen = ref(false)
    const searchValue = ref('')
    const { addTerm } = searchQueryState.searchQueryUse()
    const { getActiveConfig } = searchConfig.searchConfigurationUse()
    const { translations } = getActiveConfig()

    const onSubmit = () => {
      if (!searchValue.value) {
        return
      }

      addTerm(searchValue.value)
      gtm.pushDataLayer({
        event: 'addSearchTerm',
        searchTerm: searchValue.value
      })
      searchValue.value = ''
    }

    return {
      translations,
      searchValue,
      isOpen,
      onSubmit,
      searchPlaceholderText: translations.searchPlaceholderText
    }
  }
}
</script>

<template>
  <div class="c-overview">
    <image-text-block
      v-if="heroModel"
      :key="heroModel.title"
      :model="heroModel"
      class="mb-0"
    />
    <div class="product-overview">
      <filters />
      <filters-panel />
      <visual-filters />
      <div
        v-show="activeTermsAndFilters.length === 0"
        class="product-overview__body-area"
        v-html="slotContent"
      />
      <items-header />
      <items />
    </div>
  </div>
</template>

<script>
import { watch, computed } from 'vue'

import Items from '@/components/search/search-items-products.vue'
import ItemsHeader from '@/components/search/search-items-header.vue'

import router from '@/compositions/search/search-router'
import searchQueryState from '@/compositions/search/search-query'
import searchConfig from '@/compositions/search/search-configuration'
import routerHelper from '@/compositions/product-router-helper'
import searchFactory from '@/compositions/search/search-factory'
import Filters from '@/components/products/filters.vue'
import FiltersPanel from '@/components/products/filters-panel.vue'
import VisualFilters from '@/components/products/visual-filters.vue'
import ImageTextBlock from '@/components/image-text-block.vue'

export default {
  router,
  components: {
    FiltersPanel,
    Filters,
    VisualFilters,
    Items,
    ItemsHeader,
    ImageTextBlock
  },
  props: {
    searchConfiguration: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    slotContent: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    searchConfig.searchConfigurationUse(props.searchConfiguration)
    const { getRouteData, updateRoute } = routerHelper.useRouterHelper(router, props.baseUrl)
    const routeData = getRouteData(router)
    routeData.language = props.language

    const { defaultHero } = props.searchConfiguration
    const { activeTermsAndFilters, hero } = searchQueryState.searchQueryUse(routeData)
    const heroModel = computed(() => (hero.value ? hero.value : defaultHero))
    const { search, loading } = searchFactory.useSearchResult()
    search()

    watch(
      () => [loading.value],
      () => {
        if (!loading.value) {
          updateRoute()
        }
      }
    )

    return {
      loading,
      routeData,
      activeTermsAndFilters,
      heroModel
    }
  }
}
</script>

import searchQueryState from '@/compositions/search/search-query'
import searchConfig from '@/compositions/search/search-configuration'

export default {
  useRouterHelper(router, baseUrl) {
    const {
      getActiveConfig
    } = searchConfig.searchConfigurationUse()

    const { searchQuery } = searchQueryState.searchQueryUse()

    function updateRoute() {
      const query = {}

      const config = getActiveConfig()

      const filterParams = searchQuery.activeFilters
        .map((filter) => filter.slug).filter((x) => x)
        .sort()

      const path = baseUrl.concat(filterParams.join('+'), '/')

      const termsUrl = searchQuery.terms.sort().join('+')
      if (termsUrl !== '') {
        query.query = termsUrl
      }

      const defaultSorting = config.sortingOptions.find((x) => x.default).value
      if (searchQuery.sorting && searchQuery.sorting !== defaultSorting) {
        query.sorting = searchQuery.sorting
      }

      if (config.viewOptions && config.viewOptions.length > 1) {
        const defaultView = config.viewOptions.find((x) => x.default).value
        if (searchQuery.view && searchQuery.view !== defaultView) {
          query.sorting = searchQuery.sorting
        }
      }

      if (searchQuery.page && searchQuery.page > 1) {
        query.page = searchQuery.page
      }

      router.push({
        path,
        query
      }).catch(() => {})
    }

    function getRouteData() {
      // eslint-disable-next-line max-len
      let href = router.currentRoute.path.substr(router.currentRoute.path.lastIndexOf(baseUrl) + baseUrl.length)

      if (href.endsWith('/')) {
        href = href.substring(0, href.length - 1)
      }
      const filters = href.substring(href.lastIndexOf('/') + 1)
      const activeFiltersSlugs = (filters || '').split('+').filter((t) => t)

      const config = getActiveConfig()
      const searchType = config.type

      const activeFilters = config.filters.flatMap((a) => a.items)
        .filter((x) => activeFiltersSlugs.includes(x.slug)).map((x) => x)

      const terms = (router.currentRoute.query.query || '').split('+').filter((t) => t)
      const sorting = router.currentRoute.query.sorting || null
      const view = router.currentRoute.query.view || null
      const pageStr = router.currentRoute.query.page || '1'
      const page = parseInt(pageStr, 10)

      return {
        activeFilters,
        searchType,
        terms,
        sorting,
        view,
        page
      }
    }

    return {
      getRouteData,
      updateRoute
    }
  }
}

import { tns } from 'tiny-slider/dist/tiny-slider'
import 'tiny-slider/dist/tiny-slider.css'

export default (parent = document) => {
  const container = parent.querySelector('.js-tiny-slider')
  const navContainer = parent.querySelector('.js-tiny-slider-thumbnails')

  if (!container) {
    return
  }

  const slider = tns({
    container,
    navContainer,
    navAsThumbnails: true,
    controls: false,
    slideBy: 'page',
    mode: 'gallery',
    speed: 600,
    gutter: 0,
    items: 1,
    loop: false,
    mouseDrag: true,
    edgePadding: 0
  })

  const siderIndexChange = new CustomEvent('slideChange')

  slider.events.on('indexChanged', () => {
    const videos = container.querySelectorAll('.video-js')
    Array.from(videos).forEach((element) => {
      element.dispatchEvent(siderIndexChange)
    })
  })
}

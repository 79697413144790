import { computed, reactive, watch } from 'vue'
import api from '@/api'
import searchQueryState from './search-query'

const searchResultState = reactive({
  loading: false,
  allItemsLoaded: false,
  totalCount: 0,
  hasResults: false,
  items: [],
  termsFacet: [],
  searchResults: {},
  initialized: false
})

export default {
  useSearchResult() {
    const { searchQuery, incrementPage } = searchQueryState.searchQueryUse()
    const state = searchResultState
    const { take } = searchQuery

    async function fetchData() {
      state.items = []
      state.totalCount = 0
      state.allItemsLoaded = false
      state.hasResults = false
      const page = searchQuery.page || 1
      const skip = searchQuery.take * (page - 1)
      const response = await api.getContentItems({ ...searchQuery, skip })
      state.totalCount = response.data.total
      state.items = response.data.items
      state.hasResults = response.data.total

      if (skip + take >= state.totalCount) {
        state.allItemsLoaded = true
      }
    }

    async function fetchExtraItems() {
      const skip = searchQuery.take * (searchQuery.page - 1)

      const searchRequest = {
        language: searchQuery.language,
        skip,
        take,
        activeFilters: searchQuery.activeFilters,
        terms: searchQuery.terms,
        sorting: searchQuery.sorting,
        searchType: searchQuery.searchType
      }
      const response = await api.getContentItems(searchRequest)
      state.items.push(...response.data.items)

      if (skip + take >= state.totalCount) {
        state.allItemsLoaded = true
      }
    }

    async function search() {
      if (!state.loading) {
        state.loading = true
        fetchData().then(() => {
          state.loading = false
        })
      }
    }

    async function searchMore() {
      if (state.totalCount === state.items.length || state.allItemsLoaded) {
        return
      }

      if (!state.loading) {
        incrementPage()
        state.loading = true
        fetchExtraItems().then(() => {
          state.loading = false
        })
      }
    }

    if (!state.initialized) {
      state.initialized = true
      watch(
        () => [searchQuery.updated],
        () => {
          if (!state.loading && searchQuery.searchType) {
            search()
          }
        }
      )
    }

    return {
      allItemsLoaded: computed(() => state.allItemsLoaded),
      loading: computed(() => state.loading),
      searchResults: computed(() => state.searchResults),
      totalCount: computed(() => state.totalCount),
      items: computed(() => state.items),
      hasResults: computed(() => state.hasResults),
      search,
      searchMore
    }
  }
}

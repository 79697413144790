<template>
  <div class="product-overview__products">
    <div
      v-if="!loading && items.length === 0"
      class="product-overview__no-results"
      v-html="noResultText"
    />

    <div class="product-overview__products_list">
      <product-card
        v-for="(item, index) in items"
        :key="item.id"
        :model="item"
        :position="index + 1"
        :list-name="'Product Overview'"
      />
    </div>

    <!-- Search more -->
    <div
      v-if="!loading && !allItemsLoaded"
      class="u-flex"
    >
      <a
        :href="loadMoreLinkUrl"
        class="button-filled mx-auto"
        @click.prevent="onLoadMoreClick"
      >
        {{ loadMoreButtonText }}
      </a>
    </div>

    <!-- Loader -->
    <div
      v-if="loading"
      :class="{ 'loader-visible': loading }"
      class="inspiration-loader"
    >
      <span class="cssload-loader" />
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue'

import searchFactory from '@/compositions/search/search-factory'
import searchConfig from '@/compositions/search/search-configuration'
import ProductCard from '@/components/products/product-card.vue'
import searchQueryState from '@/compositions/search/search-query'
import gtm from '@/helpers/gtm'

export default {
  components: {
    ProductCard
  },
  setup() {
    const { getActiveConfig } = searchConfig.searchConfigurationUse()
    const {
      items, totalCount, searchMore, loading, allItemsLoaded
    } = searchFactory.useSearchResult()
    const { searchQuery } = searchQueryState.searchQueryUse()
    const { proxy } = getCurrentInstance()
    const getPageUrl = (pageN) => proxy.$route.fullPath.replace(/page=\d+/, `page=${pageN}`)
    const loadMoreLinkUrl = computed(() => getPageUrl(searchQuery.page + 1))

    const onLoadMoreClick = () => {
      gtm.pushDataLayer({
        event: 'productPageLoadMore'
      })
      searchMore()
    }

    return {
      allItemsLoaded,
      loading,
      items,
      onLoadMoreClick,
      totalCount,
      loadMoreLinkUrl,
      noResultText: computed(() => getActiveConfig().translations.noResultHtml),
      loadMoreButtonText: computed(() => getActiveConfig().translations.loadMoreButtonText)
    }
  }
}
</script>

import { ref } from 'vue'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

const filterPanelIsVisible = ref(false)
const filterPanelRef = ref(null)

export default () => {
  const showFilterPanel = () => {
    filterPanelIsVisible.value = true
    document.documentElement.classList.add('filter-opened')
    setTimeout(() => {
      disableBodyScroll(filterPanelRef.value, {
        // eslint-disable-next-line consistent-return
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.getAttribute('body-scroll-lock-ignore') !== null) {
              return true
            }

            // eslint-disable-next-line no-param-reassign
            el = el.parentElement
          }
        }
      })
    }, 100)
  }
  const hideFilterPanel = () => {
    filterPanelIsVisible.value = false
    setTimeout(() => {
      document.documentElement.classList.remove('filter-opened')
    }, 300)
    clearAllBodyScrollLocks()
  }
  const setFilterPanelRef = (el) => {
    filterPanelRef.value = el
  }

  return {
    filterPanelIsVisible,
    showFilterPanel,
    hideFilterPanel,
    setFilterPanelRef
  }
}

export default ($el, lastPosition) => {
  let scrolledUp
  const elementHeight = $el.clientHeight
  const elementRect = $el.getBoundingClientRect()
  const fixed = elementRect.top < 0

  if (lastPosition < window.pageYOffset) {
    scrolledUp = false
  }

  if (lastPosition > window.pageYOffset) {
    scrolledUp = true
  }

  const pinned = scrolledUp || elementHeight + elementRect.top > 0
  const newPosition = window.pageYOffset

  return {
    fixed,
    pinned,
    newPosition,
    elementHeight
  }
}

<template>
  <div
    ref="buyRow"
    class="product-details__buy-row"
  >
    <strong class="product-details__price">
      <span>{{ totalPrice }}</span>
      <span
        v-if="selectedVariant.priceUnit"
        class="currency"
      >
        {{ selectedVariant.priceUnit }}
      </span>
    </strong>
    <div
      v-if="model.isFreeWeight && model.shopifyInfo.variants.length > 1"
      class="select-wrapper select-wrapper--two-arrows"
    >
      <select
        @change="onChangeWeight($event.target.value)"
      >
        <option
          v-for="variant in model.shopifyInfo.variants"
          :key="variant.shopifyBase64EncodedId"
          v-text="variant.optionValue"
        />
      </select>
    </div>
    <div
      v-if="shopifyBuyableProductsQuantity > 0"
      class="select-wrapper"
    >
      <select
        @change="onChangeQuantity($event.target.value)"
      >
        <option
          v-for="i in shopifyBuyableProductsQuantity"
          :key="`amount-${i}`"
          v-text="i"
        />
      </select>
    </div>

    <buy-button
      v-if="selectedVariant"
      :variant-id="selectedVariant.shopifyBase64EncodedId"
      :track-data="trackData"
      :quantity="quantity"
    />

    <div
      v-if="hasSticky"
      ref="stickyBuyRow"
      class="c-sticky-buy-button"
    >
      <div
        v-if="shopifyBuyableProductsQuantity > 0"
        class="select-wrapper"
      >
        <select
          @change="onChangeQuantity($event.target.value)"
        >
          <option
            v-for="i in shopifyBuyableProductsQuantity"
            :key="`amount-${i}`"
            v-text="i"
          />
        </select>
      </div>

      <buy-button
        v-if="selectedVariant"
        :variant-id="selectedVariant.shopifyBase64EncodedId"
        :track-data="trackData"
        :quantity="quantity"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import stickyBuyButton from '@/components/sticky-buy-button'
import BuyButton from '@/components/products/buy-button.vue'

export default {
  components: {
    BuyButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    shopifyBuyableProductsQuantity: {
      type: Number,
      required: true
    },
    listName: {
      type: String,
      required: true
    },
    hasSticky: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const buyRow = ref()
    const stickyBuyRow = ref()
    const quantity = ref(1)
    const selectedVariant = ref(props.model.shopifyInfo.variants[0])
    const totalPrice = computed(() => selectedVariant.value.price * quantity.value)

    const trackData = computed(() => {
      const {
        name,
        category,
        brand
      } = props.model

      const {
        shopifyId: id,
        optionValue: variant,
        price
      } = selectedVariant.value

      return {
        name,
        id,
        price,
        category,
        brand,
        variant,
        list: props.listName,
        quantity: quantity.value
      }
    })

    const onChangeQuantity = (newQuantity) => {
      quantity.value = parseInt(newQuantity, 10)
    }

    const onChangeWeight = (newWeight) => {
      selectedVariant.value = props.model.shopifyInfo.variants.find((variant) => variant.weight === newWeight)
    }

    if (props.hasSticky) {
      onMounted(() => {
        stickyBuyButton(buyRow.value, stickyBuyRow.value)
      })
    }

    return {
      buyRow,
      stickyBuyRow,
      totalPrice,
      quantity,
      selectedVariant,
      trackData,

      onChangeQuantity,
      onChangeWeight
    }
  }
}
</script>

export default () => {
  const popup = document.querySelector('.js-support-popup')
  const popupOverlay = document.querySelector('.js-support-popup-overlay')
  const cookieConcentLink = popup.querySelector('.link')
  const popupOpener = popup.querySelector('.js-opener')
  const cssClass = {
    opened: 'is-opened',
    animated: 'is-animated'
  }

  const closePopup = () => {
    popup.classList.add(cssClass.animated)
    popup.classList.remove(cssClass.opened)
    setTimeout(() => {
      popup.classList.remove(cssClass.animated)
    }, 350)
    document.removeEventListener('click', closePopup, false)
  }

  const openPopup = () => {
    popup.classList.add(cssClass.animated)
    setTimeout(() => {
      popup.classList.remove(cssClass.animated)
      popup.classList.add(cssClass.opened)
    }, 0)
  }

  popupOverlay.addEventListener('click', closePopup, false)

  popupOpener.addEventListener('click', () => {
    if (popup.classList.contains(cssClass.opened)) {
      closePopup()
    } else {
      openPopup()
    }
  }, false)

  cookieConcentLink.addEventListener('click', () => {
    closePopup()
  }, false)
}

<template>
  <div
    class="basket"
    :class="{ 'is-opened': state.isOpened, 'is-basket-loading': state.isLoading }"
  >
    <div class="basket__content">
      <h4 class="basket__title">
        {{ shopifyBasket }}
      </h4>
      <ul
        v-if="state.products.length"
        class="basket__products"
      >
        <li
          v-for="product in state.products"
          :id="`line-${product.id}`"
          :key="`line-${product.id}`"
          :class="{ 'no-in-stock': !product.variant }"
          class="basket__line"
        >
          <div class="basket__product-image">
            <img
              v-if="product.variant && product.variant.image"
              :src="product.variant.image.src"
              :alt="product.title"
            >
          </div>
          <div class="basket__product-details">
            <strong class="basket__product-title">
              {{ product.title }}
            </strong>
            <div class="basket__product-controls">
              <div class="basket__product-select select-wrapper select-wrapper--two-arrows">
                <select
                  @change="updateProductQuantity(product.id, parseInt($event.target.value), 10)"
                >
                  <option
                    v-for="i in shopifyBuyableProductsQuantity"
                    :key="`amount-${product.id}-${i}`"
                    :selected="i === product.quantity"
                    v-text="i"
                  />
                </select>
              </div>
            </div>
          </div>
          <strong class="basket__product-price">
            <em
              :id="`price-${product.id.replace('=', '_').replace('=', '_')}`"
              v-text="product.variant ? (product.variant.price.amount * product.quantity) : ''"
            />
            <span>
              {{ product.variant ? product.variant.price.currencyCode : 'Sek' }}
            </span>
          </strong>
          <button
            class="basket__product-remove"
            @click="removeProductFromBasket(product)"
          >
            <svg class="basket__product-remove-icon">
              <use xlink:href="#trashcan" />
            </svg>
          </button>
        </li>
      </ul>
      <div class="basket__total">
        <strong class="basket__total-label">
          {{ shopifyTotal }}
        </strong>
        <strong class="basket__total-price">
          {{ state.totalPrice.amount }}
          {{ state.totalPrice.currencyCode }}
        </strong>
      </div>
      <a
        v-if="state.products.length"
        class="basket__go-to-checkout"
        :href="state.shopUrl"
        @click="onShopButtonClick(shopifyGoToCheckout)"
      >
        {{ shopifyGoToCheckout }}
      </a>
      <a
        v-else
        class="basket__go-to-products"
        :href="productOverviewUrl"
        @click="onContinueShoppingClick"
      >
        {{ shopifyGoToProducts }}
      </a>
      <span
        class="basket__go-back"
        @click="onCloseButtonClick(shopifyGoToCheckout)"
      >
        <span class="basket__go-back-icon" />
        {{ shopifyContinueShopping }}
      </span>
      <div
        v-if="state.isLoading"
        class="basket__loading-overlay"
      >
        <span class="cssload-loader" />
      </div>
    </div>
    <div
      class="basket__overlay"
      @click="closeBasket"
    />
  </div>
</template>

<script>
import { onMounted } from 'vue'
import useBasket from '@/compositions/useBasket'

export default {
  props: {
    shopifyBasket: {
      type: String,
      required: true
    },
    shopifyTotal: {
      type: String,
      required: true
    },
    shopifyGoToCheckout: {
      type: String,
      required: true
    },
    shopifyGoToProducts: {
      type: String,
      required: true
    },
    shopifyContinueShopping: {
      type: String,
      required: true
    },
    shopifyBuyableProductsQuantity: {
      type: Number,
      required: true
    },
    shopifyDomain: {
      type: String,
      required: true
    },
    shopifyKey: {
      type: String,
      required: true
    },
    productOverviewUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      state,

      createShopifyClient,
      openBasket,
      closeBasket,
      removeProductFromBasket,
      updateProductQuantity,
      onShopButtonClick,
      onCloseButtonClick
    } = useBasket()

    const onContinueShoppingClick = (e) => {
      if (window.isProductOverview) {
        e.preventDefault()
        closeBasket()
      }
    }

    onMounted(() => {
      createShopifyClient(props.shopifyDomain, props.shopifyKey)
    })

    return {
      state,

      openBasket,
      closeBasket,
      removeProductFromBasket,
      updateProductQuantity,
      onShopButtonClick,
      onCloseButtonClick,
      onContinueShoppingClick
    }
  }
}
</script>

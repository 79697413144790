<template>
  <div
    class="c-carousel-wrapper"
    :class="cssClass"
  >
    <div
      v-if="type !== 'uspRibbon'"
      class="c-carousel__header"
    >
      <div
        ref="mySliderControls"
        class="c-carousel__controls"
      >
        <button
          data-controls="prev"
          aria-controls="customize"
          tabindex="-1"
          class="c-carousel__navigation-button c-carousel__navigation-button-prev"
        >
          <Icon class="c-carousel__button" />
        </button>
        <button
          data-controls="next"
          aria-controls="customize"
          tabindex="-1"
          class="c-carousel__navigation-button c-carousel__navigation-button-next"
        >
          <Icon class="c-carousel__button" />
        </button>
      </div>
    </div>
    <template
      v-if="slotContent"
    >
      <div
        ref="mySlider"
        class="c-carousel"
        v-html="slotContent"
      />
    </template>
    <div
      v-else
      ref="mySlider"
      class="c-carousel"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import {
  ref, computed, onMounted, getCurrentInstance
} from 'vue'
import useSlider from '@/compositions/useSlider'
import Icon from '@/assets/svg/arrow-flat.svg'
import productCardImpression from '@/components/product-card-impression'
import gtm from '@/helpers/gtm'
import BuyProductCard from '@/components/products/buy-product-card.vue'
import vueUtil from '@/vue-util'

export default {
  components: {
    Icon
  },
  props: {
    type: {
      type: String,
      default: 'quarter'
    },
    slotContent: {
      type: String,
      default: ''
    },
    listName: {
      type: String,
      default: ''
    }
  },
  emits: ['ready', 'update', 'slide'],
  setup(props, context) {
    let slider = null
    const mySlider = ref(null)
    const mySliderControls = ref(null)
    const isDisabled = ref(false)
    const cssClass = computed(() => [
      `c-carousel-wrapper--${props.type}`,
      (isDisabled.value) ? 'is-disabled' : ''
    ])

    const handleItems = (info) => {
      isDisabled.value = info.slideCount <= info.items
    }

    const emitUpdate = (info) => {
      context.emit('update', info)
    }

    const next = () => {
      slider.goTo('next')
    }
    const prev = () => {
      slider.goTo('prev')
    }

    const onSliderInit = ({ container }) => {
      productCardImpression(container)

      const root = getCurrentInstance()
      const vueComponents = {
        BuyProductCard
      }

      setTimeout(() => {
        // And all Vue components on the page
        vueUtil.mountChildComponents(vueComponents, root.$el)
      }, 300)
    }

    onMounted(() => {
      slider = useSlider(props.type, mySlider.value, mySliderControls.value, {
        onInit: onSliderInit
      })

      slider.events.on('newBreakpointEnd', (e) => {
        handleItems(e)
        emitUpdate(e)
      })

      slider.events.on('indexChanged', (e) => {
        const direction = (e.index > e.indexCached) ? 'right' : 'left'
        const prevEnabled = e.index > 0
        const nextEnabled = e.displayIndex < e.slideCount
        const info = {
          ...e,
          direction,
          prevEnabled,
          nextEnabled
        }
        context.emit('slide', info)

        if (props.listName) {
          gtm.pushDataLayer({
            event: 'Slider',
            list: props.listName
          })
        }
      })

      const info = slider.getInfo()
      const prevEnabled = info.index > 0
      const nextEnabled = info.displayIndex < info.slideCount
      handleItems(info)

      context.emit('ready', {
        ...info,
        prevEnabled,
        nextEnabled
      })
    })

    return {
      next,
      prev,
      mySlider,
      mySliderControls,
      isDisabled,
      cssClass
    }
  }
}
</script>

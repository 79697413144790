export default function () {
  window.breakpoint = window.breakpoint || {}
  window.breakpoint.refreshValue = function () {
    this.value = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/"/g, '')
  }

  window.addEventListener('resize', () => {
    window.breakpoint.refreshValue()
  })
  window.breakpoint.refreshValue()
}

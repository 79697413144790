import Clipboard from 'clipboard'

export default (container = document) => {
  let linkText
  let timeoutRunning = false

  const trackCopyLink = () => {
    if (window.dataLayer && window.trackingData) {
      const dataObj = {
        event: 'copyLink',
        pageType: window.trackingData.pageType,
        pageTitle: window.trackingData.pageTitle
      }

      dataLayer.push(dataObj)
    }
  }

  const copyToClipboard = (element) => {
    const randomNumber = Date.now()
    const urlSegment = element.getAttribute('href')
    const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${urlSegment}`
    const text = element.querySelector('.js-text')
    const className = `copy-link-${randomNumber}`
    const fakeLink = document.createElement('a')
    fakeLink.className = className
    fakeLink.setAttribute('data-clipboard-text', url)
    fakeLink.style.cssText = `
      position:absolute;
      left:-9999px;
    `

    text.innerHTML = element.dataset.text
    document.body.appendChild(fakeLink)

    trackCopyLink()
    // eslint-disable-next-line no-unused-vars
    const clipboard = new Clipboard(`.${className}`)
    fakeLink.click()
    element.classList.add('article__share-button--active')

    timeoutRunning = true
    setTimeout(() => {
      text.innerHTML = ''
      fakeLink.remove()
      element.classList.remove('article__share-button--active')
      timeoutRunning = false
    }, 1000)
  }

  const copyLinks = container.querySelectorAll('.js-copy-link')
  Array.from(copyLinks).forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      copyToClipboard(link)
    })
    link.addEventListener('mouseenter', () => {
      if (!timeoutRunning) {
        linkText = link.querySelector('.js-text')
        linkText.innerHTML = link.dataset.hoverText
      }
    })
    link.addEventListener('mouseleave', () => {
      if (!timeoutRunning) {
        linkText = link.querySelector('.js-text')
        linkText.innerHTML = ''
      }
    })
  })
}

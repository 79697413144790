<template>
  <modal
    :adaptive="true"
    :max-width="720"
    :name="popupName"
    height="auto"
    width="90%"
    scrollable
    @before-open="beforeOpenModal()"
    @before-close="onModalClose()"
  >
    <newsletter-block
      :model="model"
      tracking-list-name="Newsletter modal"
    />
    <span
      role="button"
      aria-label="Close popup"
      class="popup-content__button--close"
      @click="hidePopup(popupName)"
    />
  </modal>
</template>

<script>
import { getCurrentInstance } from 'vue'
import useModal from '@/compositions/useModal'
import NewsletterBlock from '@/components/newsletter-block.vue'

const localStorageParam = 'newsletterIsSeen'
const delay = 3000

export default {
  components: {
    NewsletterBlock
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    popupName: {
      type: String,
      default: 'newsletter-popup'
    }
  },
  setup(props) {
    const isSeen = !!localStorage.getItem(localStorageParam)
    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      showPopup,
      hidePopup,
      beforeOpenModal,
      beforeCloseModal
    } = useModal($modal)

    const onModalClose = () => {
      beforeCloseModal()
    }

    const onCloseButtonClick = () => {
      hidePopup(props.popupName)
    }

    const initOpener = () => {
      const opener = document.querySelector('.js-newsletter-opener')
      opener.addEventListener('click', () => {
        showPopup(props.popupName)
      }, false)
    }

    const showNewsletterModal = () => {
      showPopup(props.popupName)
      localStorage.setItem(localStorageParam, 'true')
    }

    const checkCookiePopup = () => {
      if (!window.Optanon.IsAlertBoxClosed()) {
        window.Optanon.OnConsentChanged(showNewsletterModal)
      } else {
        setTimeout(() => {
          showNewsletterModal()
        }, delay)
      }
    }

    return {
      isSeen,
      initOpener,
      checkCookiePopup,
      hidePopup,
      beforeOpenModal,
      onModalClose,
      onCloseButtonClick
    }
  },
  /*
    Using mounted instead of using onMounted method in setup due
    to the issue with get getCurrentInstance() in console.
  */
  mounted() {
    this.initOpener()
    if (this.isSeen) {
      return
    }

    if (window.Optanon) {
      this.checkCookiePopup()
    } else {
      window.addEventListener('OneTrustGroupsUpdated', () => {
        this.checkCookiePopup()
      }, { once: true })
    }
  }
}
</script>

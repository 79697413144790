const getFormattedPrice = (price) => {
  const separator = price.slice(-3, -2)
  return Number(price.replace(separator, '.')).toFixed(2)
}

const keysToLowerCase = (obj) => {
  const keys = Object.keys(obj)
  const newobj = {}

  keys.forEach((key) => {
    newobj[key.toLowerCase()] = obj[key]
  })

  return newobj
}

// eslint-disable-next-line import/prefer-default-export
export { getFormattedPrice, keysToLowerCase }

// TODO: Add stickiness to filters bar
import debounce from '@/helpers/debounce'
import useSticky from '@/compositions/useSticky'

const Filters = () => {
  const module = {}
  let $body
  let stickyWrapper
  let $filters
  let $header
  let $filterItems
  let $filtersOpeners
  let $selectedContainer
  let $selectedItemsContainer
  let lastOffset
  let $filterDropdownContainer
  let $filtersDropdowns
  let $filtersPanel
  let $filtersPanelOverlay
  let $filtersPanelOpener
  let $filtersPanelCloseButtons
  let $filtersClear
  let $filtersSelectedClear
  let queryText
  const headerStickyClassName = 'is-sticky-filters'
  let lastPosition = 0
  let $isProductOverview
  let $searchFieldInput
  const websiteUrl = window.location.origin
  let filterUrls = []

  const removeActiveClass = () => {
    $filterItems.forEach((item) => {
      item.classList.remove('active')
    })
  }

  const openFilter = ($opener) => {
    const $parent = $opener.parentNode
    const $currentDropdown = $parent.querySelector('.js-filter-bar')
    if ($parent.classList.contains('opened')) {
      lastOffset = null

      $currentDropdown.classList.add('opening')

      $currentDropdown.addEventListener('transitionend', () => {
        $filterDropdownContainer.removeAttribute('style')
        $currentDropdown.classList.remove('opened')
      }, { once: true })
      $currentDropdown.classList.remove('opening')
      $filterDropdownContainer.classList.remove('opened')
      $parent.classList.remove('opened')
    } else {
      $parent.classList.add('opened')
      $currentDropdown.classList.add('opened')
      $filterDropdownContainer.classList.add('opening')

      const { height } = $currentDropdown.getBoundingClientRect()
      const $otherElements = []
      Array.from($filtersOpeners).forEach((item) => {
        if (!item.parentNode.isEqualNode($parent)) {
          $otherElements.push(item.parentNode)
        }
      })
      let noTransition = false

      const $otherElementsOpened = $otherElements.length
        ? $otherElements.filter((item) => item.classList.contains('opened'))
        : null
      if ($otherElementsOpened) {
        noTransition = true
        $filters.classList.add('no-transition')
      }

      $otherElements.forEach((item) => {
        item.classList.remove('opened')
      })
      Array.from($filtersDropdowns).forEach((item) => {
        if (!item.isEqualNode($currentDropdown)) {
          item.classList.remove('opened')
        }
      })

      if (lastOffset === height || noTransition) {
        $filters.classList.add('no-transition')
        $filterDropdownContainer.classList.remove('opening')

        $parent.classList.add('opened')
      } else {
        $selectedContainer.addEventListener('transitionend', () => {
          lastOffset = height

          $filterDropdownContainer.classList.remove('opening')
          $filterDropdownContainer.classList.add('opened')

          $parent.classList.add('opened')
        }, { once: true })
      }

      setTimeout(() => {
        $filters.classList.remove('no-transition')
      }, 50)
    }
  }

  const updateUrl = ({ urlFilterIds }) => {
    /*
      NOTE: Keep in mind that websiteUrl will always be top level
      domain only (due to location.origin),
      so language url segment is not currently supported for filters.
    */
    if (window.history) {
      const { baseUrlSegment } = $filtersPanel.dataset
      const filters = `${urlFilterIds.sort().join('/')}/`
      window.history.pushState({ module: 'leave' }, document.title, `${websiteUrl}/${baseUrlSegment}/${urlFilterIds.length ? filters : ''}`)
    }
  }

  const calculateFiltersState = () => {
    const filterIds = []
    const urlFilterIds = []
    const $selectedItems = [...$filterItems].filter((item) => item.classList.contains('active'))

    $selectedItems.forEach(($element) => {
      const { filterid, filterUrlSegment } = $element.dataset

      if (filterIds.indexOf(filterid) === -1 && filterid) {
        filterIds.push(filterid)
      }
      if (urlFilterIds.indexOf(filterUrlSegment) === -1 && filterUrlSegment) {
        urlFilterIds.push(filterUrlSegment)
      }
    })

    const searchEvent = new CustomEvent('search.filters', {
      detail: {
        filterIds,
        queryText
      }
    })
    const navigationEvent = new CustomEvent('navigation.filters', {
      detail: { urlFilterIds }
    })

    document.body.dispatchEvent(searchEvent)
    document.body.dispatchEvent(navigationEvent)
    if (!$isProductOverview) {
      updateUrl({ urlFilterIds })
    }

    return $selectedItems
  }

  const getSelectedFiltersHtml = () => {
    const $htmlArray = []
    const $items = calculateFiltersState()

    $items.forEach((item) => {
      const $element = item.cloneNode(true)

      $element.insertAdjacentHTML('beforeEnd', '<button class="filter__button__remove js-remove-button"><span></span></button>')
      $htmlArray.push($element)
    })

    return $htmlArray
  }

  const getSearchFieldTag = () => {
    const searchTagNameTemplate = document.createElement('li')
    searchTagNameTemplate.classList.add('active')
    searchTagNameTemplate.innerHTML = `
      <a class="filter__button" href="#">
        ${queryText}
      </a>
      <button class="filter__button__remove js-remove-search-tag"><span></span></button>
    `

    return queryText ? searchTagNameTemplate : null
  }

  const clearSearchTag = () => {
    const $item = $filters.querySelector('.js-remove-search-tag')
    queryText = ''
    if ($item) {
      $item.classList.remove('active')
    }
    $searchFieldInput.value = ''
    // eslint-disable-next-line no-use-before-define
    renderSelectedItems()
  }

  const addClearAllLink = () => {
    const $li = document.createElement('li')
    $li.classList.add('clear')
    $li.appendChild($filtersSelectedClear)

    $filtersSelectedClear.addEventListener('click', () => {
      removeActiveClass()
      clearSearchTag()
    })

    $selectedItemsContainer.appendChild($li)
  }

  const renderSelectedItems = () => {
    const $items = getSelectedFiltersHtml()
    const $selectedSearchTag = getSearchFieldTag()

    if ($selectedSearchTag) {
      $items.push($selectedSearchTag)
    }

    if ($items.length) {
      let $html = ''
      $items.forEach((item) => {
        $html += item.outerHTML
      })
      $selectedItemsContainer.innerHTML = $html
      addClearAllLink()
      $selectedContainer.classList.add('visible')
      $filters.classList.add('filter-selected')
      $filters.parentNode.classList.add('filters-selected')
    } else {
      $selectedContainer.classList.remove('visible')
      $filters.classList.remove('filter-selected')
      $filters.parentNode.classList.remove('filters-selected')
      setTimeout(() => {
        $selectedItemsContainer.innerHTML = ''
      }, 150)
    }
  }

  const removeFilter = (filterId) => {
    $filterItems.forEach((item) => {
      if (item.dataset.filterid === filterId) {
        item.classList.remove('active')
      }
    })
    renderSelectedItems()
  }

  const showInitialItems = () => {
    document.querySelector('.initial_items').classList.remove('initial_items_hidden')
  }

  const restoreFilters = () => {
    const { pathname } = window.location
    const { baseUrlSegment } = $filtersPanel.dataset
    const relativepathname = pathname.replace(baseUrlSegment, '')
    if (relativepathname !== '/') {
      const filters = relativepathname.split('/').filter((element) => element)
      let $filtersItems = []
      let filterIds = []

      for (let i = 0; i < filters.length; i += 1) {
        const filter = document.querySelector(`[data-filter-url-segment="${filters[i]}"]`)
        if (filter) {
          filter.classList.add('active')
          $filtersItems.push(filter)
          filterIds.push(filter.dataset.filterid)
          filterUrls.push(filters[i])
        } else {
          $filtersItems = []
          filterIds = []
          filterUrls = []
          removeActiveClass()
          break
        }
      }

      const restoreFiltersEvent = new CustomEvent('restore.filters', {
        detail: { filterIds, filterUrls }
      })
      document.body.dispatchEvent(restoreFiltersEvent)

      if (typeof filterIds[0] !== 'undefined') {
        $selectedContainer.classList.add('visible')
        $filters.classList.add('filter-selected')
        $filters.parentNode.classList.add('filters-selected')

        $filtersItems.forEach((item) => {
          const $element = item.cloneNode(true)

          $element.insertAdjacentHTML('beforeEnd', '<button class="filter__button__remove js-remove-button"><span></span></button>')

          $selectedItemsContainer.insertAdjacentHTML('beforeEnd', $element.outerHTML)
        })

        addClearAllLink()
      }

      if (filterUrls.length === 0) {
        showInitialItems()
      }
    }
  }

  const trackExpandFilter = (filterCategory) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        filterCategory,
        event: $isProductOverview ? 'productPageExpandFilter' : 'expandFilter'
      })
    }
  }

  const trackFiltering = (filterName, filterCategory) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        filterName,
        filterCategory,
        event: $isProductOverview ? 'productPageFilterSelection' : 'filterSelection'
      })
    }
  }

  const appendFilterTag = (e) => {
    e.preventDefault()
    const $filterItem = e.currentTarget
    const filterCategory = $filterItem
      .parentNode
      .parentNode
      .parentNode
      .querySelector('.js-filter-opener')?.innerText.trim()

    filterUrls.push($filterItem.dataset.filterUrlSegment)

    $filterItem.classList.toggle('active')
    if ($filterItem.classList.contains('active')) {
      trackFiltering($filterItem.dataset.title, filterCategory)
    }

    renderSelectedItems()

    const uniqueUrls = filterUrls.filter((item, pos) => filterUrls.indexOf(item) === pos)
    document.body.dispatchEvent(new CustomEvent('pass.filterUrls', { detail: { uniqueUrls } }))
  }

  const openFilterPanel = () => {
    $filtersPanel.classList.add('is-open')
    $body.classList.add('filter-opened')
  }

  const closeFilterPanel = () => {
    $filtersPanel.classList.remove('is-open')
    $body.classList.remove('filter-opened')
  }

  const updateScrollValues = debounce(() => {
    const {
      fixed, pinned, newPosition, elementHeight
    } = useSticky(stickyWrapper, lastPosition)

    lastPosition = newPosition
    stickyWrapper.style.height = `${elementHeight}px`
    stickyWrapper.classList[pinned ? 'add' : 'remove']('is-pinned')

    if (fixed) {
      $header.classList.add(headerStickyClassName)
      stickyWrapper.classList.add('is-sticky')
    } else {
      $header.classList.remove(headerStickyClassName)
      stickyWrapper.classList.remove('is-sticky')
    }
  }, 10)

  const attachEvents = () => {
    window.addEventListener('scroll', updateScrollValues)
    $filterItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        if (item.dataset.link !== undefined) {
          return
        }
        appendFilterTag(e)
      })
    })

    $filtersPanelOpener.addEventListener('click', (e) => {
      e.preventDefault()
      openFilterPanel()
    })

    $filtersPanelOverlay.addEventListener('click', (e) => {
      e.preventDefault()
      closeFilterPanel()
    })

    $filtersOpeners.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault()
        if (!e.currentTarget.parentNode.classList.contains('opened')) {
          trackExpandFilter(e.currentTarget.innerText.trim())
        }
        openFilter(e.currentTarget)
      })
    })

    $filtersPanelCloseButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault()
        closeFilterPanel()
      })
    })

    $filtersClear.addEventListener('click', (e) => {
      e.preventDefault()
      removeActiveClass()
      renderSelectedItems()
      closeFilterPanel()
    })

    $selectedContainer.addEventListener('click', (e) => {
      if (e.target.classList.contains('js-remove-button')) {
        const item = e.target.parentNode
        let uniqueUrls = []
        const { title, filterUrlSegment, filterid } = item.dataset

        filterUrls = filterUrls.filter((url) => url !== filterUrlSegment)

        removeFilter(filterid)

        uniqueUrls = filterUrls.filter((url, pos) => filterUrls.indexOf(url) === pos)

        document.body.dispatchEvent(new CustomEvent('pass.removeEvent', { detail: { title } }))
        document.body.dispatchEvent(new CustomEvent('pass.filterUrls', { detail: { uniqueUrls } }))
      } else if (e.target.classList.contains('js-remove-search-tag')) {
        clearSearchTag()
      }
    })

    document.body.addEventListener('query.searchTagName', (e) => {
      queryText = e.detail.searchTagName
      renderSelectedItems()
    })
  }

  const expandFirstItem = () => {
    const $el = $filtersOpeners[0]
    $el.parentNode.classList.add('opened', 'is-categories')
    $el.classList.remove('js-filter-opener')
    $filtersOpeners = $filters.querySelectorAll('.js-filter-opener')
  }

  const addOpenerLabels = () => {
    [...$filtersOpeners].forEach((element) => {
      const items = element.parentNode.querySelectorAll('.js-filter-item')
      const itemCount = items ? items.length : 0
      element.insertAdjacentHTML('beforeEnd', `<span>(${itemCount})</span>`)
    })
  }

  module.init = () => {
    stickyWrapper = document.getElementById('sticky-wrapper')
    $filters = document.querySelector('.js-filters')
    if (!$filters || document.body.classList.contains('edit-mode')) {
      return
    }

    $body = document.body
    $isProductOverview = !$filters.classList.contains('js-frontpage-filter')
    $header = document.querySelector('.js-site-header')

    $searchFieldInput = document.querySelector('.js-search-field-input')
    $filterDropdownContainer = $filters.querySelector('.js-filters-append')
    $filtersDropdowns = $filters.querySelectorAll('.js-filter-bar')
    $filterItems = $filters.querySelectorAll('.js-filter-item')
    $filtersOpeners = $filters.querySelectorAll('.js-filter-opener')
    $selectedContainer = $filters.querySelector('.js-selected-filters')
    $selectedItemsContainer = $filters.querySelector('.js-selected-filters-container')

    $filtersPanel = $filters.querySelector('.js-filters-panel')
    $filtersPanelOpener = $filters.querySelector('.js-filters-panel-opener')
    $filtersPanelOverlay = $filters.querySelector('.js-filters-panel-overlay')
    $filtersPanelCloseButtons = $filters.querySelectorAll('.js-filters-panel-close')
    $filtersClear = $filters.querySelector('.js-filters-clear')
    $filtersSelectedClear = $filters.querySelector('.js-filters-selected-clear')

    expandFirstItem()
    addOpenerLabels()
    attachEvents()
    restoreFilters()
    updateScrollValues()
  }

  return module
}

export default new Filters()

<template>
  <div
    v-if="totalCount > 0"
    class="search-overview__top"
  >
    <h1 class="search-overview__heading">
      {{ title }} <span>({{ totalCount }})</span>
    </h1>
  </div>
</template>

<script>
import { computed } from 'vue'
import searchFactory from '@/compositions/search/search-factory'
import searchConfig from '@/compositions/search/search-configuration'
import searchQueryState from '@/compositions/search/search-query'

export default {
  setup() {
    const { totalCount } = searchFactory.useSearchResult()
    const { getActiveConfig, isOnlineFilter } = searchConfig.searchConfigurationUse()
    const { totalCountMessage } = getActiveConfig().translations
    const { activeFilters } = searchQueryState.searchQueryUse()

    const title = computed(() => {
      const filteredFilters = activeFilters.value
        .filter((activeFilter) => !isOnlineFilter(activeFilter.name))
      return filteredFilters.length
        ? filteredFilters.map((x) => x.name).join(' & ')
        : totalCountMessage
    })

    return {
      totalCount,
      title
    }
  }
}
</script>

import searchQueryState from '@/compositions/search/search-query'
import searchConfig from '@/compositions/search/search-configuration'

export default {
  useRouterHelper(router, baseUrl) {
    const {
      getActiveConfig
    } = searchConfig.searchConfigurationUse()

    const { searchQuery } = searchQueryState.searchQueryUse()
    const searchParams = new URLSearchParams(window.location.search)

    function updateRoute() {
      const query = {}
      const path = baseUrl
      const config = getActiveConfig()

      // Set filters
      const filterParams = searchQuery.activeFilters
        .map((filter) => filter.slug).filter((x) => x)
        .sort().join('+')
      if (filterParams !== '') {
        query.filters = filterParams
      }

      // Set search term
      const termsUrl = searchQuery.terms.join('+')
      if (termsUrl !== '') {
        query.query = termsUrl
      }

      const defaultSorting = config.sortingOptions.find((x) => x.default).value
      if (searchQuery.sorting && searchQuery.sorting !== defaultSorting) {
        query.sorting = searchQuery.sorting
      }

      if (searchQuery.page !== 1) {
        query.page = searchQuery.page
      }

      router.push({
        path,
        query
      }).catch(() => {})
    }

    function getRouteData() {
      const config = getActiveConfig()

      const query = searchParams.get('query')
      const terms = (query || '').split('+').filter((t) => t)
      const sorting = router.currentRoute.query.sorting
        || config.sortingOptions.find((x) => x.default).value
      const filters = searchParams.get('filters')
      const activeFiltersSlugs = (filters || '').split('+').filter((t) => t)
      const activeFilters = config.filters.flatMap((a) => a.items)
        .filter((x) => activeFiltersSlugs.includes(x.slug))

      const pageStr = router.currentRoute.query.page || '1'
      const page = parseInt(pageStr, 10)
      const searchType = config.type

      return {
        activeFilters,
        searchType,
        terms,
        sorting,
        page
      }
    }

    return {
      getRouteData,
      updateRoute
    }
  }
}

<template>
  <div
    class="search-overview__items"
    :class="{ 'no-results': items.length === 0 }"
  >
    <div
      v-if="!loading && items.length === 0"
      class="search-overview__no-results"
      v-html="noResultText"
    />

    <div class="search-overview__items_list">
      <item-card
        v-for="(item, index) in items"
        :key="item.id"
        :model="item"
        :position="index + 1"
        :list-name="'Search Overview'"
        :class="{ 'item-card--large': index % 2 === 0 }"
      />
    </div>

    <!-- Search more -->
    <div
      v-if="!loading && !allItemsLoaded"
      class="u-flex"
    >
      <a
        :href="loadMoreLinkUrl"
        class="button-outline mx-auto"
        @click.prevent="onLoadMoreClick"
      >
        {{ loadMoreButtonText }}
      </a>
    </div>

    <!-- Loader -->
    <div
      v-if="loading"
      :class="{ 'loader-visible': loading }"
      class="inspiration-loader"
    >
      <span class="cssload-loader" />
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue'

import searchFactory from '@/compositions/search/search-factory'
import searchConfig from '@/compositions/search/search-configuration'
import ItemCard from '@/components/item-card.vue'
import searchQueryState from '@/compositions/search/search-query'
import gtm from '@/helpers/gtm'

export default {
  components: {
    ItemCard
  },
  setup() {
    const { getActiveConfig } = searchConfig.searchConfigurationUse()
    const {
      items, totalCount, searchMore, loading, allItemsLoaded
    } = searchFactory.useSearchResult()
    const { searchQuery } = searchQueryState.searchQueryUse()
    const { proxy } = getCurrentInstance()
    const getPageUrl = (pageN) => proxy.$route.fullPath.replace(/page=\d+/, `page=${pageN}`)
    const loadMoreLinkUrl = computed(() => getPageUrl(searchQuery.page + 1))

    const onLoadMoreClick = () => {
      gtm.pushDataLayer({
        event: 'searchOverviewLoadMore'
      })
      searchMore()
    }

    return {
      allItemsLoaded,
      loading,
      items,
      totalCount,
      loadMoreLinkUrl,
      noResultText: computed(() => getActiveConfig().translations.noResultHtml),
      loadMoreButtonText: computed(() => getActiveConfig().translations.loadMoreButtonText),

      onLoadMoreClick
    }
  }
}
</script>

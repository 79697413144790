<template>
  <div class="product-card__buy-row">
    <buy-button
      v-if="selectedVariant"
      :variant-id="selectedVariant.shopifyBase64EncodedId"
      :track-data="trackData"
      :quantity="quantity"
    />
    <span
      v-if="model.weight"
      class="product-card-weight"
    >
      {{ model.weight }}
    </span>
    <strong class="product__price">
      <span>{{ totalPrice }}</span>
      <span
        v-if="selectedVariant.priceUnit"
        class="currency"
      >
        {{ selectedVariant.priceUnit }}
      </span>
    </strong>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import BuyButton from '@/components/products/buy-button.vue'

export default {
  components: {
    BuyButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    listName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const quantity = ref(1)
    const selectedVariant = ref(props.model.shopifyInfo.variants[0])
    const totalPrice = computed(() => selectedVariant.value.price * quantity.value)

    const trackData = computed(() => {
      const {
        name,
        category,
        brand
      } = props.model

      const {
        shopifyBase64EncodedId: id,
        optionValue: variant,
        price
      } = selectedVariant.value

      return {
        name,
        id,
        price,
        category,
        brand,
        variant,
        list: props.listName,
        quantity: quantity.value
      }
    })

    return {
      totalPrice,
      quantity,
      selectedVariant,
      trackData
    }
  }
}
</script>

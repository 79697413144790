import { reactive } from 'vue'
import Client from 'shopify-buy'
import gtm from '@/helpers/gtm'

const trackListName = 'Mini Cart'

const state = reactive({
  isInitiated: false,
  isOpened: false,
  isMessageShown: false,
  isLoading: false,
  products: [],
  shopUrl: '',
  checkoutId: null,
  totalPrice: 0
})
let client = null

const openBasket = () => {
  state.isOpened = true
  gtm.trackOpenBasket()
}

const closeBasket = () => {
  state.isOpened = false
}

const productAdded = () => {
  state.emptyBasket = false
  state.isMessageShown = true
  setTimeout(() => {
    state.isMessageShown = false
  }, 1500)
}

const setCheckoutId = (id) => {
  window.localStorage.setItem('shopifyCheckoutId', id)
}

const getCheckoutId = () => window.localStorage.getItem('shopifyCheckoutId')

const updateURLWithClientId = () => {
  const checkIfGAisInited = () => {
    if (typeof window.ga === 'function') {
      window.ga(() => {
        const clientId = window.ga.getAll()[0].get('clientId')
        const link = document.createElement('a')
        link.setAttribute('href', state.shopUrl)
        const params = new URLSearchParams(link.search)
        params.set('clientId', clientId)
        link.search = params
        state.shopUrl = link.href
      })
    } else {
      setTimeout(checkIfGAisInited, 500)
    }
  }
  checkIfGAisInited()
}

const initBasket = (checkout) => {
  state.isInitiated = true
  state.totalPrice = checkout.totalPrice
  state.shopUrl = checkout.webUrl
  state.products = checkout.lineItems
  updateURLWithClientId()
}

const createClient = () => {
  client.checkout.create().then((checkout) => {
    setCheckoutId(checkout.id)
    state.checkoutId = checkout.id
  })
}

const createShopifyClient = (domain, storefrontAccessToken) => {
  client = Client.buildClient({
    domain,
    storefrontAccessToken
  })

  state.checkoutId = getCheckoutId()

  if (state.checkoutId) {
    client.checkout.fetch(state.checkoutId)
      .then((checkout) => {
        if (checkout.completedAt) {
          createClient()
        } else {
          initBasket(checkout)
        }
      })
      .catch(() => {
        // if an old and now invalid value was stored in local storage, then renew it
        createClient()
      })
  } else {
    createClient()
  }
}

const onShopButtonClick = (btnName) => {
  const products = []

  state.products.forEach((product) => {
    const {
      quantity, price, name
    } = product

    products.push({
      name,
      price,
      quantity
    })
  })

  gtm.checkoutOrder({ step: 1, products })
  gtm.trackCheckout(btnName)
}

const onCloseButtonClick = (btnName) => {
  closeBasket()
  gtm.trackContinueShopping(btnName)
}

const removeProductFromBasket = (product) => {
  if (client) {
    const {
      id,
      quantity,
      title: name,
      variant: {
        price
      }
    } = product

    state.isLoading = true
    client.checkout.removeLineItems(state.checkoutId, [id])
      .then((checkout) => {
        gtm.removeFromCart({
          name,
          quantity,
          list: trackListName,
          price
        })
        gtm.trackProductRemoved(name)
        state.totalPrice = checkout.totalPrice
        state.products = checkout.lineItems
      })
      .finally(() => {
        state.isLoading = false
      })
  }
}

const updateProductQuantity = (productId, quantity) => {
  const productIndex = state.products.findIndex(({ id }) => id === productId)
  if (productIndex === -1) {
    return
  }

  const {
    quantity: prevQuantity,
    title,
    id,
    variant: {
      price
    }
  } = state.products[productIndex]

  state.isLoading = true
  gtm.trackProductUpdate({
    prevQuantity, quantity, title, price, list: trackListName
  })

  client.checkout.updateLineItems(state.checkoutId, [{ id, quantity }])
    .then((checkout) => {
      gtm.trackQuantityChange(quantity)
      state.totalPrice = checkout.totalPrice
      state.products = checkout.lineItems
    })
    .finally(() => {
      state.isLoading = false
    })
}

const fetchAndCompareIds = (storedId) => new Promise((resolve, reject) => {
  client.checkout.fetch(storedId)
    .then((checkout) => {
      resolve(storedId === checkout.id)
    })
    .catch((e) => {
      reject(e)
    })
})

const ensureCheckoutId = () => new Promise((resolve) => {
  const storedId = getCheckoutId()
  fetchAndCompareIds(storedId)
    .then(() => resolve())
    .catch(() => {
      // stored value is invalid, recreate and store it before adding to basket
      client.checkout.create().then((checkout) => {
        setCheckoutId(checkout.id)
        state.checkoutId = checkout.id
        resolve()
      })
    })
})

const addProductToBasket = ({ variantId, quantity, trackData }) => {
  if (client) {
    state.isLoading = true
    ensureCheckoutId().then(() => {
      // eslint-disable-next-line max-len
      client.checkout.addLineItems(state.checkoutId, { variantId, quantity })
        .then((checkout) => {
          state.isLoading = false
          productAdded()
          initBasket(checkout)
          gtm.addToCart(trackData)
        })
    })
  }
}

// eslint-disable-next-line import/prefer-default-export
export default () => ({
  state,

  createShopifyClient,
  openBasket,
  closeBasket,
  onShopButtonClick,
  onCloseButtonClick,
  removeProductFromBasket,
  updateProductQuantity,
  addProductToBasket
})

export default ($modal) => {
  const showPopup = (modalName) => {
    $modal.show(modalName)
  }

  const hidePopup = (modalName) => {
    $modal.hide(modalName)
  }

  const beforeOpenModal = (bodyCssClasses = ['show-v-modal']) => {
    bodyCssClasses.forEach((className) => {
      document.documentElement.classList.add(className)
    })
  }

  const beforeCloseModal = (bodyCssClasses = ['show-v-modal']) => {
    bodyCssClasses.forEach((className) => {
      document.documentElement.classList.remove(className)
    })
  }

  return {
    showPopup,
    hidePopup,
    beforeOpenModal,
    beforeCloseModal
  }
}

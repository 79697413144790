<template>
  <div>
    <div
      v-if="!isVisible"
      ref="container"
      class="c-video-placeholder"
    />
    <Video
      v-if="isVisible"
      :src="src"
      :heading="heading"
      :show-btn-play="showBtnPlay"
    >
      <div
        v-if="slotContent"
        v-html="slotContent"
      />
    </Video>
  </div>
</template>

<script>
import viewport from '@/helpers/in-viewport-detect'

export default {
  components: {
    Video: () => import('@/components/Video.vue')
  },
  props: {
    src: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      default: 'Default'
    },
    showBtnPlay: {
      type: Boolean,
      default: true
    },
    slotContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: '0px'
    }
    viewport.inViewport(this.$refs.container, () => {
      this.isVisible = true
    }, options)
  }
}
</script>

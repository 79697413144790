<template>
  <button
    class="buy-button"
    @click="onButtonClick"
  >
    <svg class="basket-button__icon">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#basket" />
    </svg>
    <span>{{ buyButtonText }}</span>
  </button>
</template>

<script>
import useBasket from '@/compositions/useBasket'

export default {
  props: {
    trackData: {
      type: Object,
      required: true
    },
    variantId: {
      type: String,
      required: true
    },
    quantity: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const { addProductToBasket } = useBasket()
    const buyButtonText = window.buyButtonText === '' ? 'Köp' : window.buyButtonText

    const onButtonClick = () => {
      addProductToBasket(props)
    }

    return {
      buyButtonText,
      onButtonClick
    }
  }
}
</script>

import 'awesomplete'

const config = {
  class: {
    isOpen: 'is-open',
    isVisible: 'is-visible',
    readyForTyping: 'ready-for-typing'
  },
  selector: {}
}

class SearchField {
  constructor() {
    this.isOpen = false
    this.filters = []
    this.selectedFilters = []

    this.$root = document.querySelector('.js-search-field')

    if (!this.$root) {
      return
    }
    this.$inner = document.querySelector('.js-filter-inner-items')
    this.$filters = this.$inner.querySelectorAll('.js-filter-item')

    this.init()
  }

  findNodes() {
    this.$searchSubmit = this.$root.querySelector('.js-search-field-submit')
    this.$searchIcon = this.$root.querySelector('.js-search-field-icon')
    this.$searchIconClose = this.$root.querySelector('.js-search-field-close')
    this.$searchInput = this.$root.querySelector('.js-search-field-input')
    this.$searchForm = this.$root.querySelector('.js-search-field-form')
    this.autoCompleteTitel = this.$root.querySelector('.js-autocomplete-titel')
  }

  static trackSearch(searchTerm, searchResult) {
    if (window.dataLayer) {
      window.dataLayer.push({
        searchTerm,
        searchResult,
        event: 'submitSearch'
      })
    }
  }

  attachEvents() {
    this.$searchIcon.addEventListener('touchstart click', (e) => {
      e.preventDefault()
      this.activateSearchField()
    })

    this.$searchIconClose.addEventListener('touchstart click', (e) => {
      e.preventDefault()
      this.activateSearchField()
    })

    this.$searchInput.addEventListener('touchstart click', () => {
      if (!this.isOpen) {
        this.activateSearchField()
      }
    })

    this.$searchInput.addEventListener('keyup', (e) => {
      const keyCode = e.keyCode || e.which
      if (keyCode !== 13) {
        this.detectTyping()
      } else {
        const callback = (event) => {
          if (event.detail?.searchResult) {
            const searchResult = e.detail.searchResult || ''
            SearchField.trackSearch(this.$searchInput.value, searchResult)
          }
        }
        document.body.removeEventListener('input.trackSearch', callback)
        document.body.addEventListener('input.trackSearch', callback)
      }
    })

    this.$searchForm.addEventListener('submit', (e) => {
      e.preventDefault()
      if (this.inputValueLength >= 2) {
        this.addFilterTag()
        this.activateSearchField()
      }
    })
    document.body.addEventListener('pass.removeEvent', (e) => {
      this.removeSelectedFilterFromArr(e.detail)
    })
  }

  updateFiltersBasedOnDomState() {
    const that = this
    Array.from(this.$filters).forEach(($filter) => {
      if (!$filter.classList.contains('active')) {
        that.filters.push($filter.dataset.title)
      }
    })
    that.filters.sort()
  }

  removeSelectedFilterFromArr(data) {
    const dataValue = data.value
    const index = this.selectedFilters.indexOf(dataValue)

    if (index !== -1) {
      this.selectedFilters.splice(index, 1)
    }
  }

  addAutocomplete() {
    if (!this.$searchInput) {
      return
    }
    const awesomplete = new window.Awesomplete(this.$searchInput, {
      minChars: 2,
      maxItems: 5,
      sort(a, b) { return a - b },

      replace() {
        this.input.value = ''
      }
    })

    this.updateFiltersBasedOnDomState()
    awesomplete.list = this.filters

    this.$searchInput.addEventListener('awesomplete-select', (e) => {
      const selectedData = e.text.value
      const includeData = this.selectedFilters.includes(selectedData)
      const tempArr = []

      Array.from(this.$filters).forEach(($filter) => {
        const text = $filter.dataset.title

        if (text.trim() === selectedData && !includeData) {
          tempArr.push(text)
          $filter.click() // TODO: check if it works
        }
      })

      this.selectedFilters = this.selectedFilters.concat(tempArr)

      this.isOpen = true
      this.activateSearchField()
    })

    document.body.addEventListener('pass.filterUrls', () => {
      this.updateFiltersBasedOnDomState()
    })
  }

  addFilterTag() {
    function escapeHtml(string) {
      const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
      }
      return String(string).replace(/[&<>"'`=/]/g, (s) => entityMap[s])
    }
    const searchTagName = escapeHtml(this.$searchInput.value)
    document.body.dispatchEvent(new CustomEvent('query.searchTagName', { detail: { searchTagName } }))
  }

  activateSearchField() {
    if (this.isOpen) {
      this.$root.addEventListener('transitionend', () => {
        this.$searchInput.blur()
      }, { once: true })
      this.$searchIcon.classList.remove(config.class.readyForTyping)
      this.$root.classList.remove(config.class.isOpen)
      this.$searchSubmit.classList.remove(config.class.isVisible)

      this.isOpen = false
    } else {
      this.$root.addEventListener('transitionend', () => {
        this.$searchInput.focus()
      }, { once: true })
      this.$searchIcon.classList.add(config.class.readyForTyping)
      this.$root.classList.add(config.class.isOpen)
      this.isOpen = true

      if (this.inputValue > 0) {
        this.detectTyping()
      }
    }
  }

  detectTyping() {
    this.inputValue = this.$searchInput.value
    this.inputValueLength = this.inputValue.trim().length

    if (this.inputValueLength >= 2) {
      this.$searchIcon.classList.remove(config.class.readyForTyping)
      this.$searchSubmit.classList.add(config.class.isVisible)
    } else {
      this.$searchIcon.classList.add(config.class.readyForTyping)
      this.$searchSubmit.classList.remove(config.class.isVisible)
    }
  }

  init() {
    this.findNodes()
    this.attachEvents()
    this.addAutocomplete()
  }
}

export default new SearchField()

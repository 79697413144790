const YoutubeApi = (function () {
  let instance

  function createInstance() {
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    return Promise.resolve(true)
  }

  return {
    async load() {
      if (!instance) {
        instance = await createInstance()
      }
      return instance
    }
  }
}())

export default YoutubeApi

import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json'

export default {
  getContentItems({
    language, skip, take, searchType, activeFilters = [], terms = [], sorting = null
  }) {
    let sortBy = sorting
    if (terms && terms.length > 0) {
      sortBy = 'rank'
    }
    const params = {
      skip,
      take,
      sorting: sortBy,
      terms: encodeURIComponent(terms.join('|')),
      filters: encodeURIComponent(activeFilters.filter((x) => !x.isBrand).map((filter) => filter.uid || filter.name).join('|')),
      brands: encodeURIComponent(activeFilters.filter((x) => x.isBrand).map((filter) => filter.uid).join('|')),
      searchType
    }

    return axios.get(`/${language}/api/search/v2/contentItems`, { params })
  },
  subscribeUser(model, recaptchaToken) {
    return axios.post('/sv/api/newsletter/signup', model, {
      headers: {
        RecaptchaToken: recaptchaToken
      }
    })
  },
  getMoreInspirationItems({
    language, articlesNumber, recipesNumber, filterIds, queryText, relevantFilterIds
  }) {
    const params = {
      articlesNumber,
      recipesNumber,
      filterIds,
      queryText,
      relevantFilterIds
    }

    return axios.get(`/${language}/api/search`, { params })
  }
}

import api from '@/api'
import VideoBlock from '@/components/video-block'
import TextTruncate from '@/components/text-truncate'
import Slider from '@/components/slider.vue'
import vueUtil from '@/vue-util'

const EndlessScroll = () => {
  const module = {}
  let $container
  let $filtersSelected
  let lang
  let index = 1
  let $loader
  let isLoading = false
  let articlesNumber = 0
  let recipesNumber = 0
  let filterIds = []
  let hasReachedTheEnd = false
  let $noResults
  let queryText
  let relevantFilterIds
  let isEmptyResponse = false
  let $frontpageHeading
  let firstHeroFromResponse = ''
  const containerInitialClass = 'inspiration-area js-inspiration'

  const setBGColor = (bgColor) => {
    const items = document.querySelectorAll('.four-articles__bg, .js-video-block')
    items.forEach((element) => {
      element.classList.add('inline-hex-color')
      element.style.backgroundColor = bgColor
    })
  }

  const hideFrontpageHeading = () => {
    const textBlocks = document.querySelector('.campaign-text-block')
    if ($frontpageHeading && textBlocks) {
      $frontpageHeading.remove()
    } else {
      const { parentNode } = $container
      parentNode.insertBefore($frontpageHeading, $container)
    }
  }

  const showEmptyResults = () => {
    hasReachedTheEnd = true
    setTimeout(() => {
      $container.classList.remove('search-hidden')

      document.body.removeAttribute('style')

      setTimeout(() => {
        $container.classList.remove('no-transition')
        $noResults.classList.add('visible')
      }, 100)
    }, 0)
  }

  const replaceHero = (newHero) => {
    if (firstHeroFromResponse !== newHero) {
      firstHeroFromResponse = newHero
      const wrapper = document.querySelector('.frontpage__filter-wrapper')
      const $appendedHero = document.querySelector('.js-hero-block.appended')
      wrapper.parentNode.insertAdjacentHTML('beforebegin', newHero)

      const $newHero = document.querySelector('.js-hero-block:not(.appended)')
      $newHero.classList.add('appended')

      setTimeout(() => {
        $newHero.classList.add('animated')
        if ($appendedHero) {
          $appendedHero.remove()
        }
      }, 50)
    }
  }

  const addWithAnimation = (element, parent, placement = 'afterbegin') => {
    parent.insertAdjacentHTML(placement, element)
  }

  const initializeVideo = () => {
    const videoBlocks = $container.querySelectorAll('.js-video-block')
    videoBlocks.forEach((element) => {
      // eslint-disable-next-line no-unused-vars
      const youtube = new VideoBlock(element)
    })
  }

  const addBodyBlocks = (campaignBlocksHtml) => {
    const bodyBlocks = `<div class="body-blocks">${campaignBlocksHtml.join('')}</div>`
    addWithAnimation(bodyBlocks, $container)
    initializeVideo()
  }

  const removeElements = (cssSelector, withAnimation = true) => {
    const elements = document.querySelectorAll(cssSelector)
    if (!elements) {
      return
    }

    Array.from(elements).forEach((element) => {
      if (withAnimation) {
        element.classList.remove('animated')
        element.addEventListener('transitionend', () => {
          element.remove()
        }, { once: true })
      } else {
        element.remove()
      }
    })
  }

  const renderNoResults = () => {
    // TODO: There is no emptySearchText property in BE...
    const emptySearchText = window.emptySearchText || 'Ingenting hittas'
    const { parentNode } = $loader
    const noResultsHolder = document.createElement('div')
    noResultsHolder.className = 'inspiration-no-results js-no-results'
    noResultsHolder.innerHTML = `<div class="inspiration-no-results__inner">${emptySearchText}</div>`
    parentNode.insertBefore(noResultsHolder, $loader)
  }

  const getMoreItems = () => {
    isLoading = true
    $loader.classList.add('loader-visible')
    $noResults.classList.remove('visible')
    return api.getMoreInspirationItems({
      language: lang, articlesNumber, recipesNumber, filterIds, queryText, relevantFilterIds
    })
  }

  const trackLoadContent = (_index) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        count: _index,
        event: 'loadMoreContent'
      })
    }
  }

  const checkIfNewItemsNeeded = () => {
    if (isLoading
      || hasReachedTheEnd
      || isEmptyResponse
      || $container.getBoundingClientRect().bottom > window.innerHeight * 2.5
    ) {
      return
    }

    getMoreItems().then(({ data }) => {
      if (data.IsEmpty === true) {
        isEmptyResponse = true
      }

      if (data.IsEmpty === true
        && articlesNumber === 0
        && recipesNumber === 0
        && isEmptyResponse) {
        showEmptyResults()
      } else {
        const blocks = data.Blocks.join('')
        const matchedArticles = blocks.match(/data-type="Article"/g)
        const matchedRecipes = blocks.match(/data-type="Recipe"/g)
        articlesNumber += matchedArticles ? matchedArticles.length : 0
        recipesNumber += matchedRecipes ? matchedRecipes.length : 0
        $container.insertAdjacentHTML('beforeend', blocks)
        trackLoadContent(index)
        index += 1
        initializeVideo()
        TextTruncate($container)
      }

      const blocksColor = $container.getAttribute('data-color-code')
      if (blocksColor !== '') {
        setBGColor(blocksColor)
      }
    }).catch((e) => {
      console.error(e)
    }).finally(() => {
      isLoading = false
      $loader.classList.remove('loader-visible')
    })
  }

  const prepareAnimation = () => {
    const height = $container.offsetHeight

    $container.style.height = `${height}px`
    $container.replaceChildren()
    document.body.style.overflowY = 'scroll'

    const currentScrollPosition = window.scrollY
    const targetScrollPosition = $container.getBoundingClientRect().top - 50

    if (currentScrollPosition > targetScrollPosition) {
      window.scrollTo(0, targetScrollPosition)
    }
    $loader.classList.add('filtering')
  }

  const afterDataFetch = (dataToFetch) => {
    $loader.classList.remove('loader-visible')
    isLoading = false
    $loader.classList.remove('filtering')

    $container.removeAttribute('style')

    if (dataToFetch.IsEmpty === true) {
      isEmptyResponse = true
      showEmptyResults()
    } else {
      isEmptyResponse = false

      $container.innerHTML = dataToFetch.Blocks.join('')
      $container.classList.add('no-transition')

      if (!articlesNumber && !recipesNumber && !filterIds.length && !queryText.length) {
        initializeVideo()
      }

      const blocks = dataToFetch.Blocks.join('')
      const matchedArticles = blocks.match(/data-type="Article"/g)
      const matchedRecipes = blocks.match(/data-type="Recipe"/g)
      articlesNumber += matchedArticles ? matchedArticles.length : 0
      recipesNumber += matchedRecipes ? matchedRecipes.length : 0
      checkIfNewItemsNeeded()

      setTimeout(() => {
        $container.classList.remove('search-hidden')

        document.body.removeAttribute('style')

        setTimeout(() => {
          $container.classList.remove('no-transition')
        }, 10)
      }, 0)

      if (dataToFetch.Campaign !== null) {
        const {
          Type, Image, Text, BodyArea, BlockBackgroundColor, BlockBackgroundHex
        } = dataToFetch.Campaign

        if (Image !== null) {
          replaceHero(Image)
        }

        if (Type === 'Brand' && filterIds.length <= 1) {
          addBodyBlocks(BodyArea)
        } else if (Type === 'Custom') {
          addBodyBlocks(BodyArea)
        }

        if (Text != null) {
          addWithAnimation(Text, $container)
          TextTruncate($container)
        }

        if (BlockBackgroundColor != null) {
          $container.setAttribute('class', containerInitialClass)
          $container.classList.add(BlockBackgroundColor)
        }

        if (BlockBackgroundHex != null) {
          setBGColor(BlockBackgroundHex)
          $container.setAttribute('data-color-code', BlockBackgroundHex)
        }
      } else {
        $container.setAttribute('class', containerInitialClass)
        const items = document.querySelectorAll('.four-articles__bg, .js-video-block')
        items.forEach((element) => {
          element.classList.remove('inline-hex-color')
          element.removeAttribute('style')
        })
        $container.removeAttribute('data-color-code')
        firstHeroFromResponse = ''
        removeElements('.body-blocks', false)
        removeElements('.appended')
      }

      hideFrontpageHeading()
      vueUtil.mountComponents({ Slider }, null, $container)
    }
  }

  const getDataFromServer = () => {
    if (isLoading) {
      return
    }

    getMoreItems().then(({ data }) => {
      afterDataFetch(data)

      let searchResult

      if (data.IsEmpty) {
        searchResult = 'No'
      } else {
        searchResult = 'Yes'
      }

      const list = $filtersSelected.querySelector('ul')
      if (!list.childNodes.length) {
        const trackSearchEvent = new CustomEvent('input.trackSearch', {
          detail: {
            searchResult
          }
        })
        document.body.dispatchEvent(trackSearchEvent)
      }
    }).catch((e) => {
      console.error(e)
    })
  }

  const attachEvents = () => {
    const articles = document.querySelectorAll('[data-type="Article"]')
    articlesNumber += articles ? articles.length : 0
    const recipes = document.querySelectorAll('[data-type="Recipe"]')
    recipesNumber += recipes ? recipes.length : 0
    const bodyBlocks = document.querySelectorAll('.body-blocks')
    if (bodyBlocks.length) {
      let campaignArticlesNumber = 0
      let campaignRecipesNumber = 0

      bodyBlocks.forEach((element) => {
        const articleBodyBlocks = element.querySelectorAll('[data-type="Article"]')
        const recipeBodyBlocks = element.querySelectorAll('[data-type="Recipe"]')

        campaignArticlesNumber += articleBodyBlocks ? recipeBodyBlocks.length : 0
        campaignRecipesNumber += recipeBodyBlocks ? recipeBodyBlocks.length : 0
      })

      articlesNumber -= campaignArticlesNumber
      recipesNumber -= campaignRecipesNumber
    }

    window.addEventListener('scroll', () => {
      requestAnimationFrame(checkIfNewItemsNeeded)
    })

    document.body.addEventListener('search.filters', (e) => {
      hasReachedTheEnd = false
      articlesNumber = 0
      recipesNumber = 0
      filterIds = e.detail.filterIds
      queryText = e.detail.queryText || ''

      if ($container.classList.contains('search-hidden')) {
        getDataFromServer()
      } else {
        $container.addEventListener('transitionend', () => {
          prepareAnimation()
          getDataFromServer()
        }, { once: true })
        $container.classList.add('search-hidden')
      }
    })

    document.body.addEventListener('restore.filters', (e) => {
      filterIds = e.detail.filterIds
    })

    const colorCode = $container.getAttribute('data-color-code')
    if (colorCode !== '') {
      setBGColor(colorCode)
    }
  }

  module.init = () => {
    $container = document.querySelector('.js-inspiration')
    if (!$container) {
      return
    }
    relevantFilterIds = $container.dataset.relevantFilterIds
    lang = document.documentElement.getAttribute('lang')
    $loader = document.querySelector('.js-inspiration-loader')
    $filtersSelected = document.querySelector('.js-selected-filters')
    renderNoResults()
    $noResults = document.querySelector('.js-no-results')
    $frontpageHeading = document.querySelector('.frontpage__heading')
    initializeVideo()
    hideFrontpageHeading()
    attachEvents()
  }

  return module
}

export default new EndlessScroll()

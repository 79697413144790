import YoutubeApi from './youtube-api'
import debounce from '../helpers/debounce'

const isInViewportPartially = (element) => {
  const rect = element.getBoundingClientRect()

  // eslint-disable-next-line max-len
  return ((rect.bottom - window.innerHeight) <= rect.height * 0.8) && (rect.top > -rect.height * 0.8)
}

const VideoBlock = (container) => {
  const module = {}
  let player
  let autoPlay = false

  const playVideoOnClick = () => {
    if (player) {
      player.playVideo()
    }
  }

  const playVideoIfInViewport = () => {
    if (!player) {
      return
    }
    if (isInViewportPartially(container)) {
      player.mute()
      player.playVideo()
    } else {
      player.pauseVideo()
    }
  }

  const initPlayerOnClick = (id) => {
    player = new window.YT.Player(`video-${id}`, {
      playerVars: {
        rel: 0, modestbranding: 1, showinfo: 0
      },
      host: 'https://www.youtube-nocookie.com',
      videoId: id,
      events: {
        onReady: playVideoOnClick
      }
    })
  }

  const attachEvents = () => {
    if (autoPlay) {
      const onScroll = debounce(() => {
        playVideoIfInViewport()
      }, 50)

      window.addEventListener('scroll', () => {
        onScroll()
      })
    }

    const playButton = container.querySelector('.js-play-button')
    if (playButton) {
      playButton.addEventListener('click', () => {
        initPlayerOnClick(container.dataset.id)
      })
    }

    container.addEventListener('slideChange', () => {
      if (player && player.getPlayerState() === 1) {
        player.pauseVideo()
      }
    })
  }

  const initPlayer = (id) => {
    player = new window.YT.Player(`video-${id}`, {
      playerVars: {
        rel: 0, modestbranding: 1, showinfo: 0
      },
      host: 'https://www.youtube-nocookie.com',
      videoId: id,
      events: {
        onReady: playVideoIfInViewport
      }
    })
  }

  const initYoutubeScript = () => YoutubeApi.load()

  const init = () => {
    if (container.dataset.youtubeVideo) {
      return
    }

    if (container.dataset.autoPlay) {
      autoPlay = true
    }

    initYoutubeScript().then(() => {
      let prevCallback

      if (window.YT?.loaded) {
        container.setAttribute('data-youtube-video', true)
        if (autoPlay) {
          initPlayer(container.dataset.id)
        }
        attachEvents()
      } else {
        if (typeof window.onYouTubeIframeAPIReady === 'function') {
          prevCallback = window.onYouTubeIframeAPIReady
        }

        window.onYouTubeIframeAPIReady = function () {
          container.setAttribute('data-youtube-video', true)

          if (prevCallback) {
            prevCallback()
          }
          if (autoPlay) {
            initPlayer(container.dataset.id)
          }
          attachEvents()
        }
      }
    })
  }

  init()

  return module
}

export default VideoBlock

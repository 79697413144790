import Router from 'vue-router'

const searchRouter = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      name: 'filters'
    }
  ]
})

export default searchRouter

<template>
  <div class="basket-button-wrap">
    <button
      class="basket-button"
      :class="{ 'basket-loading': state.isLoading }"
      @click="openBasket"
    >
      <svg class="basket-button__icon">
        <use xlink:href="#basket" />
      </svg>
      <span
        :class="{ 'is-visible': countText || state.isLoading }"
        class="basket-button__count"
      >
        <span class="basket-button__count-text">
          {{ countText }}
        </span>
        <svg class="basket-button__loader">
          <use xlink:href="#loader-arrows" />
        </svg>
      </span>
    </button>
    <span
      :class="{ 'is-shown': state.isMessageShown }"
      class="basket-button__add-to-basket-msg"
    >
      {{ message }}
    </span>
  </div>
</template>

<script>
import { computed } from 'vue'
import useBasket from '@/compositions/useBasket'

export default {
  props: {
    message: {
      type: String,
      required: true
    }
  },
  setup() {
    const {
      state,

      openBasket
    } = useBasket()

    // eslint-disable-next-line max-len
    const countText = computed(() => (
      state.products.length
        ? state.products.reduce((previousValue, product) => previousValue + product.quantity, 0)
        : 0
    ))

    return {
      state,
      countText,

      openBasket
    }
  }
}
</script>

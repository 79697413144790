import { keysToLowerCase } from '@/helpers/data-transformations'

const pushDataLayer = (obj) => {
  if (window.dataLayer) {
    window.dataLayer.push(obj)
  }
}

const productImpression = ({
  list, name, id, price, category, brand, variant, position = null
}) => {
  const gtmObj = {
    event: 'eec.impressionView',
    ecommerce: {
      currencyCode: 'kr',
      impressions: [{
        list,
        name,
        id,
        price,
        category,
        brand,
        variant,
        position
      }]
    }
  }

  // push to gtm
  pushDataLayer(gtmObj)
}

const productClicks = ({
  list, name, id, price, category, brand, variant, position = null
}) => {
  const gtmObj = {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list },
        products: [{
          name,
          id,
          price,
          category,
          brand,
          variant,
          position
        }]
      }
    }
  }

  // push to gtm
  pushDataLayer(gtmObj)
}

const addToCart = ({
  list, name, id, price, category, brand, variant, quantity
}) => {
  const gtmObj = {
    event: 'addToCart',
    ecommerce: {
      add: {
        actionField: { list },
        products: [{
          name,
          id,
          price,
          category,
          brand,
          variant,
          quantity
        }]
      }
    }
  }

  // push to gtm
  pushDataLayer(gtmObj)
}

const removeFromCart = ({
  list, name, id, price, category, brand, variant, quantity
}) => {
  const gtmObj = {
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        actionField: { list },
        products: [{
          name,
          id,
          price,
          category,
          brand,
          variant,
          quantity
        }]
      }
    }
  }

  // push to gtm
  pushDataLayer(gtmObj)
}

const checkoutOrder = ({ step, products }) => {
  const gtmObj = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step
        }
      }
    }
  }

  if (products) {
    const productsLowerCase = []
    products.forEach((product) => {
      productsLowerCase.push(keysToLowerCase(product))
    })
    gtmObj.ecommerce.checkout.products = productsLowerCase
  }

  // push to gtm
  pushDataLayer(gtmObj)
}

// video
const videoStart = (heading) => {
  const gtmObj = {
    event: 'videoStart',
    videoTitle: heading
  }
  pushDataLayer(gtmObj)
}

const videoComplete = (heading) => {
  const gtmObj = {
    event: 'videoComplete',
    videoTitle: heading
  }
  pushDataLayer(gtmObj)
}

const videoProgress = (heading, duration) => {
  const gtmObj = {
    event: 'videoProgress',
    videoPercent: `${duration}`,
    videoTitle: heading
  }
  pushDataLayer(gtmObj)
}

const trackOpenBasket = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'openCart'
    })
  }
}

const trackCheckout = (btnName) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      buttonClick: btnName,
      event: 'cartCTA'
    })
  }
}

const trackContinueShopping = (btnName) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      buttonClick: btnName,
      event: 'cartCTA'
    })
  }
}

const trackProductRemoved = (variantName) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      productName: variantName,
      event: 'deletedItem'
    })
  }
}

const trackProductUpdate = ({
  prevQuantity, quantity, title, price, list
}) => {
  if (prevQuantity > quantity) {
    removeFromCart({
      name: title,
      price,
      quantity: (prevQuantity - quantity),
      list
    })
  } else {
    addToCart({
      name: title,
      price,
      quantity: (quantity - prevQuantity),
      list
    })
  }
}

const trackQuantityChange = (quantityAmount) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      itemChange: quantityAmount,
      event: 'changedQuantity'
    })
  }
}

export default {
  pushDataLayer,
  productImpression,
  productClicks,
  addToCart,
  removeFromCart,
  checkoutOrder,
  videoProgress,
  videoStart,
  videoComplete,
  trackCheckout,
  trackContinueShopping,
  trackOpenBasket,
  trackProductRemoved,
  trackQuantityChange,
  trackProductUpdate
}

<template>
  <div
    v-if="onlineCategoryName !== null"
    class="show-buyable-control form-row--checkbox"
  >
    <label class="form-row--choice__item">
      <input
        v-model="showOnlyBuyable"
        type="checkbox"
        class="form-checkbox"
        @change="onChange"
      >
      <span class="form-choice__label">{{ showOnlineProductsText }}</span>
      <span class="form-choice__indicator" />
    </label>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import searchQueryState from '@/compositions/search/search-query'
import searchConfig from '@/compositions/search/search-configuration'

export default {
  setup() {
    const { getActiveConfig } = searchConfig.searchConfigurationUse()
    const { onlineFilterName, translations } = getActiveConfig()
    const { addFilter, removeFilter, activeFilters } = searchQueryState.searchQueryUse()
    const showOnlyBuyable = ref(activeFilters.value.some((name) => name === onlineFilterName))
    const onlineFilter = {
      type: 'filter',
      slug: '',
      name: onlineFilterName
    }

    const onChange = () => {
      if (showOnlyBuyable.value) {
        addFilter(onlineFilter)
      } else {
        removeFilter(onlineFilter)
      }

      window.dataLayer.push({
        toggleChange: showOnlyBuyable.value ? 'On' : 'Off',
        event: 'ecommerceToggle'
      })
    }

    return {
      onChange,
      showOnlyBuyable,
      onlineCategoryName: computed(() => onlineFilterName || null),
      showOnlineProductsText: translations.showOnlineProductsOnly
    }
  }
}
</script>
